import type { UserState } from '@/store/modules/user';
import type { OrdersState } from '@/store/modules/orders';
import type { FavouritesState } from '@/store/modules/favourites';
import type { MessagesState } from '@/store/modules/messages';
import type { CartState } from '@/store/modules/cart';

export type State = {
  noTransitions: boolean;
};

export type RootState = {
  user: UserState;
  orders: OrdersState;
  favourites: FavouritesState;
  messages: MessagesState;
  cart: CartState;
} & State;

export const state = {
  noTransitions: false,
};
