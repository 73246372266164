import { reactive, ref } from 'vue';

export function useServiceWorker() {
  const hasUpdate = ref(false);
  const refresh = ref(() => {});

  // const setHasUpdate = () => (hasUpdate.value = true);

  // import(
  //   /* webpackChunkName: "registerServiceWorker" */ './useRegisterServiceWorker'
  // ).then(({ useRegisterServiceWorker }) => {
  //   const { skipWaiting } = useRegisterServiceWorker(setHasUpdate);
  //
  //   refresh.value = skipWaiting;
  // });

  return reactive({
    hasUpdate,
    refresh,
  });
}
