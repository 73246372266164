import type { RequestUrlAndHeaders } from '@/api/http/types';

const enum CachingStrategies {
  CacheFirst = 'CacheFirst',
  NetworkFirst = 'NetworkFirst',
  StaleWhileRevalidate = 'StaleWhileRevalidate',
}

const exactPathToStrategyMatches = {
  'common-state': CachingStrategies.NetworkFirst,
  favourites: CachingStrategies.NetworkFirst,
  messages: CachingStrategies.NetworkFirst,
  filters: CachingStrategies.StaleWhileRevalidate,
  'home/books': CachingStrategies.NetworkFirst,
  'search/suggestions': CachingStrategies.StaleWhileRevalidate,
} as Record<string, CachingStrategies>;

const strategyRules = {
  [CachingStrategies.CacheFirst]: (path: string) =>
    path.startsWith('book/preview'),
  [CachingStrategies.StaleWhileRevalidate]: (path: string) => {
    return (
      path.startsWith('catalog') ||
      (path.startsWith('book/') && path.endsWith('/page-data'))
    );
  },
} as Record<CachingStrategies, (path: string) => boolean>;

const resolveStrategy = (path: string) => {
  if (path in exactPathToStrategyMatches) {
    return exactPathToStrategyMatches[path];
  }

  for (const strategy in strategyRules) {
    if (strategyRules[strategy as CachingStrategies](path)) {
      return strategy;
    }
  }

  return null;
};

export function addCachingStrategyHeader(config: RequestUrlAndHeaders) {
  if (!config.url) {
    return;
  }

  const cachingStrategy = resolveStrategy(config.url);

  if (cachingStrategy !== null) {
    config.headers = config.headers || {};
    config.headers['x-caching-strategy'] = cachingStrategy;
  }

  return;
}
