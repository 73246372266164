import { setCartBookCount } from '@/api/cart.api';
import type { HttpClientResponse } from '@/api/http';
import { broadcaster, BroadcasterMessage } from '@/broadcaster';
import { getCart, setCart } from '@/services/cart.service';
import type { OrderBook } from '@/components/OrderBook/types';
import { GetterTypes } from '@/store/constants/getter-types';
import { ActionTypes } from '@/store/constants/action-types';
import { MutationTypes } from '@/store/constants/mutation-types';
import type { AugmentedActionContext } from './actions';
import type { CartInfo } from './types';

export async function rollbackOnError(
  { commit, getters }: AugmentedActionContext,
  fn: () => Promise<HttpClientResponse>,
): Promise<HttpClientResponse> {
  const cartBooks = [...getters[GetterTypes.CART_BOOKS]];
  const cartInfo = getCart();

  const res = await fn();

  // if error
  if (res[0]) {
    commit(MutationTypes.SET_CART_BOOKS, cartBooks);
    commit(MutationTypes.SET_CART_INFO, cartInfo);
    setCart(cartInfo);
  }

  return res;
}

export async function apiSetCartBookCount(
  { rootGetters, getters, dispatch }: AugmentedActionContext,
  bookVariationId: number,
  count: number,
) {
  if (!rootGetters[GetterTypes.LOGGED_IN]) {
    return [null, count];
  }

  const [error, res] = await setCartBookCount(bookVariationId, count);

  const apiCount = res?.count ?? null;

  const lastStoredCount =
    getters[GetterTypes.CART_BOOK_ITEM_COUNT](bookVariationId);

  if (!error && apiCount !== null && lastStoredCount !== apiCount) {
    await dispatch(ActionTypes.SET_CART_STORE_BOOK_COUNT, {
      bookVariationId,
      count: apiCount,
    });
  }

  return [error, apiCount ?? lastStoredCount];
}

export function cartBooksToCartInfo(books: OrderBook[]): CartInfo {
  return Object.assign(
    {},
    ...books.map(({ variations, count, price, basePrice }) => ({
      [variations[0].id]: { count, price, basePrice },
    })),
  );
}

export function syncCartBooksWithCartInfo(
  books: OrderBook[],
  cartInfo: CartInfo,
): OrderBook[] {
  return books
    .filter((book) => book.variations[0].id in cartInfo)
    .map((book) => {
      const { count, price, basePrice } = cartInfo[book.variations[0].id];

      return { ...book, count, price, basePrice };
    });
}

export function broadcastAddToCart(cartInfo: CartInfo, book: OrderBook) {
  broadcaster.post(BroadcasterMessage.ADDED_TO_CART, {
    cartInfo,
    book,
  });
}

export function broadcastRemoveFromCart(
  cartInfo: CartInfo,
  bookVariationId: number,
) {
  broadcaster.post(BroadcasterMessage.REMOVED_FROM_CART, {
    cartInfo,
    bookVariationId,
  });
}

export function broadcastSetCartBookCount(
  cartInfo: CartInfo,
  bookVariationId: number,
  count: number,
) {
  broadcaster.post(BroadcasterMessage.COUNT_CHANGED_IN_CART, {
    cartInfo,
    bookVariationId,
    count,
  });
}
