import debounce from 'lodash/debounce';

interface DebounceSettings {
  leading?: boolean;
  maxWait?: number;
  trailing?: boolean;
}

export const createDebounced = (wait?: number, options?: DebounceSettings) =>
  debounce(
    (callback: (...args: unknown[]) => unknown) => callback(),
    wait || 500,
    options,
  );
