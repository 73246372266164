import { cIC, rIC } from './idle-callback-polyfills';
import { queueMicrotask } from './lib/queueMicrotask';
import { now } from './lib/now';

const DEFAULT_MIN_TASK_TIME = 0;

// @ts-expect-error: error TS2304: Cannot find name 'safari'.
const isSafari_ = !!(typeof safari === 'object' && safari.pushNotification);

type TaskState = {
  time: number;
  visibilityState: DocumentVisibilityState;
};

type Task = {
  state: TaskState;
  task: (state?: TaskState) => void;
  minTaskTime: number;
};

/**
 * A class wraps a queue of requestIdleCallback functions for two reasons:
 *   1. So other callers can know whether or not the queue is empty.
 *   2. So we can provide some guarantees that the queued functions will
 *      run in unload-type situations.
 */
export class IdleQueue {
  private idleCallbackHandle: number | null = null;
  private taskQueue: Task[] = [];
  private isProcessing = false;
  private state: TaskState | null = null;
  private ensureTasksRun = false;
  private defaultMinTaskTime = DEFAULT_MIN_TASK_TIME;

  /**
   * Creates the IdleQueue instance and adds lifecycle event listeners to
   * run the queue if the page is hidden (with fallback behavior for Safari).
   */
  constructor({
    ensureTasksRun = false,
    defaultMinTaskTime = DEFAULT_MIN_TASK_TIME,
  } = {}) {
    this.ensureTasksRun = ensureTasksRun;
    this.defaultMinTaskTime = defaultMinTaskTime;

    this.runTasksImmediately = this.runTasksImmediately.bind(this);
    this.runTasks = this.runTasks.bind(this);
    this.onVisibilityChange = this.onVisibilityChange.bind(this);

    if (this.ensureTasksRun) {
      addEventListener('visibilitychange', this.onVisibilityChange, true);

      // Safari does not reliably fire the `pagehide` or `visibilitychange`
      // events when closing a tab, so we have to use `beforeunload` with a
      // timeout to check whether the default action was prevented.
      // - https://bugs.webkit.org/show_bug.cgi?id=151610
      // - https://bugs.webkit.org/show_bug.cgi?id=151234
      // NOTE: we only add this to Safari because adding it to Firefox would
      // prevent the page from being eligible for bfcache.
      if (isSafari_) {
        addEventListener('beforeunload', this.runTasksImmediately, true);
      }
    }
  }

  pushTask(task: () => void, params?: { minTaskTime: number }) {
    this.addTask(Array.prototype.push, task, params);
  }

  unshiftTask(task: () => void, params?: { minTaskTime: number }) {
    this.addTask(Array.prototype.unshift, task, params);
  }

  /**
   * Runs all scheduled tasks synchronously.
   */
  runTasksImmediately() {
    // By not passing a deadline, all tasks will be run sync.
    this.runTasks();
  }

  hasPendingTasks() {
    return this.taskQueue.length > 0;
  }

  /**
   * Clears all pending tasks for the queue and stops any scheduled tasks
   * from running.
   */
  clearPendingTasks() {
    this.taskQueue = [];
    this.cancelScheduledRun();
  }

  /**
   * Returns the state object for the currently running task. If no task is
   * running, null is returned.
   */
  getState() {
    return this.state;
  }

  /**
   * Destroys the instance by unregistering all added event listeners and
   * removing any overridden methods.
   */
  destroy() {
    this.taskQueue = [];
    this.cancelScheduledRun();

    if (this.ensureTasksRun) {
      removeEventListener('visibilitychange', this.onVisibilityChange, true);

      // Safari does not reliably fire the `pagehide` or `visibilitychange`
      // events when closing a tab, so we have to use `beforeunload` with a
      // timeout to check whether the default action was prevented.
      // - https://bugs.webkit.org/show_bug.cgi?id=151610
      // - https://bugs.webkit.org/show_bug.cgi?id=151234
      // NOTE: we only add this to Safari because adding it to Firefox would
      // prevent the page from being eligible for bfcache.
      if (isSafari_) {
        removeEventListener('beforeunload', this.runTasksImmediately, true);
      }
    }
  }

  addTask(
    arrayMethod: typeof Array.prototype.push | typeof Array.prototype.push,
    task: () => void,
    { minTaskTime = this.defaultMinTaskTime } = {},
  ) {
    const state = {
      time: now(),
      visibilityState: document.visibilityState,
    };

    arrayMethod.call(this.taskQueue, { state, task, minTaskTime });

    this.scheduleTasksToRun();
  }

  /**
   * Schedules the task queue to be processed. If the document is in the
   * hidden state, they queue is scheduled as a microtask so it can be run
   * in cases where a macrotask couldn't (like if the page is unloading). If
   * the document is in the visible state, `requestIdleCallback` is used.
   */
  scheduleTasksToRun() {
    if (this.ensureTasksRun && document.visibilityState === 'hidden') {
      queueMicrotask(this.runTasks);
    } else {
      if (!this.idleCallbackHandle) {
        this.idleCallbackHandle = rIC(this.runTasks) as number;
      }
    }
  }

  /**
   * Runs as many tasks in the queue as it can before reaching the
   * deadline. If no deadline is passed, it will run all tasks.
   * If an `IdleDeadline` object is passed (as is with `requestIdleCallback`)
   * then the tasks are run until there's no time remaining, at which point
   * we yield to input or other script and wait until the next idle time.
   */
  runTasks(deadline?: IdleDeadline) {
    this.cancelScheduledRun();

    if (!this.isProcessing) {
      this.isProcessing = true;

      // Process tasks until there's no time left or we need to yield to input.
      while (
        this.hasPendingTasks() &&
        !shouldYield(deadline, this.taskQueue[0].minTaskTime)
      ) {
        const { task, state } = this.taskQueue.shift()!;

        this.state = state;
        task(state);
        this.state = null;
      }

      this.isProcessing = false;

      if (this.hasPendingTasks()) {
        // Schedule the rest of the tasks for the next idle time.
        this.scheduleTasksToRun();
      }
    }
  }

  /**
   * Cancels any scheduled idle callback and removes the handler (if set).
   */
  cancelScheduledRun() {
    if (this.idleCallbackHandle) {
      cIC(this.idleCallbackHandle);
    }

    this.idleCallbackHandle = null;
  }

  /**
   * A callback for the `visibilitychange` event that runs all pending
   * callbacks immediately if the document's visibility state is hidden.
   */
  onVisibilityChange() {
    if (document.visibilityState === 'hidden') {
      this.runTasksImmediately();
    }
  }
}

/**
 * Returns true if the IdleDealine object exists and the remaining time is
 * less or equal to than the minTaskTime. Otherwise returns false.
 */
const shouldYield = (
  deadline: IdleDeadline | undefined,
  minTaskTime: number,
) => {
  return !!(deadline && deadline.timeRemaining() <= minTaskTime);
};
