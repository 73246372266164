import type { InjectionKey } from 'vue';
import type {
  CommitOptions,
  DispatchOptions,
  StoreOptions,
  Store as VuexStore,
} from 'vuex';
import { createLogger, createStore, useStore as baseUseStore } from 'vuex';
import { actions } from './actions';
import { userModule } from '@/store/modules/user';
// import {ordersModule} from '@/store/modules/orders';
import { favouritesModule } from '@/store/modules/favourites';
import { messagesModule } from '@/store/modules/messages';
import { cartModule } from '@/store/modules/cart';
import type { Actions } from '@/store/actions';
import { state, type RootState } from '@/store/state';
import { mutations, type Mutations } from '@/store/mutations';
import type { Getters } from '@/store/getters';

const debug = process.env.NODE_ENV !== 'production';
const plugins = debug ? [createLogger({})] : [];

export const storeOptions: StoreOptions<RootState> = {
  modules: {
    user: userModule,
    // orders: ordersModule,
    favourites: favouritesModule,
    messages: messagesModule,
    cart: cartModule,
  },
  state: state as RootState,
  mutations,
  actions,
  strict: debug,
  plugins,
};

export const key: InjectionKey<VuexStore<RootState>> = Symbol();

export function useStore() {
  return baseUseStore(key);
}

export const store = createStore<RootState>(storeOptions);

export type { RootState } from './state';

type AugmentedGetters = {
  [K in keyof Getters]: ReturnType<Getters[K]>;
};

export type AugmentedCommit = {
  <K extends keyof Mutations, P extends Parameters<Mutations[K]>[1]>(
    key: K,
    payload?: P,
    options?: CommitOptions,
  ): ReturnType<Mutations[K]>;
};

export type AugmentedDispatch = {
  <K extends keyof Actions>(
    key: K,
    payload?: Parameters<Actions[K]>[1],
    options?: DispatchOptions,
  ): ReturnType<Actions[K]>;
};

export type Store = Omit<typeof store, 'getters' | 'commit' | 'dispatch'> & {
  getters: AugmentedGetters;
  commit: AugmentedCommit;
  dispatch: AugmentedDispatch;
};
