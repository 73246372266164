import type { MutationTree } from 'vuex';
import type { State } from './state';
import { MutationTypes } from '@/store/constants/mutation-types';
import type { OrderBook } from '@/components/OrderBook/types';
import type { CartInfo, Coupon, Promocode } from '@/store/modules/cart/types';

export interface Mutations<S = State> {
  [MutationTypes.SET_CART_INFO](state: S, cartInfo: CartInfo): void;
  [MutationTypes.SET_CART_BOOKS](state: S, books: OrderBook[]): void;
  [MutationTypes.SET_CART_BOOK](state: S, book: OrderBook): void;
  [MutationTypes.SET_CART_BOOK_COUNT](
    state: S,
    payload: { bookVariationId: number; count: number },
  ): void;
  [MutationTypes.SET_CART_LOADED](state: S, loaded: boolean): void;
  [MutationTypes.SET_CART_LOAD_LOCK](state: S, lock: boolean): void;
  [MutationTypes.REMOVE_FROM_CART](state: S, bookVariationId: number): void;
  [MutationTypes.SET_CART_COUPONS](state: S, coupons: Coupon[]): void;
  [MutationTypes.SET_CART_COUPON_ID](state: S, couponId: number | null): void;
  [MutationTypes.SET_CART_PROMOCODE](
    state: S,
    promocode: Promocode | null,
  ): void;
  [MutationTypes.SET_CART_PROMOCODE_ID](
    state: S,
    promocodeId: number | null,
  ): void;
}

export const mutations: MutationTree<State> & Mutations = {
  [MutationTypes.SET_CART_INFO](state, cartInfo): void {
    state.cartInfo = cartInfo;
  },
  [MutationTypes.SET_CART_BOOKS](state, books): void {
    state.cartBooks = books;
  },
  [MutationTypes.SET_CART_BOOK](state, book): void {
    const existingBookIdx = state.cartBooks.findIndex(
      (b) => b.variations[0].id == book.variations[0].id,
    );

    if (existingBookIdx !== -1) {
      state.cartBooks[existingBookIdx] = book;
    } else {
      state.cartBooks = [...state.cartBooks, book];
    }
  },
  [MutationTypes.SET_CART_BOOK_COUNT](
    state,
    { bookVariationId: bookVariationId, count },
  ): void {
    const idx = state.cartBooks.findIndex(
      (book) => book.variations[0].id == bookVariationId,
    );

    if (idx >= 0) {
      state.cartBooks[idx].count = count;
    }
  },
  [MutationTypes.SET_CART_LOADED](state, loaded = true): void {
    state.loaded = loaded;
  },
  [MutationTypes.SET_CART_LOAD_LOCK](state, lock = true): void {
    state.loadLock = lock;
  },
  [MutationTypes.REMOVE_FROM_CART](state, bookVariationId): void {
    const idx = state.cartBooks.findIndex(
      (book) => book.variations[0].id == bookVariationId,
    );

    if (idx >= 0) {
      state.cartBooks.splice(idx, 1);
    }
  },
  [MutationTypes.SET_CART_COUPONS](state, coupons): void {
    state.coupons = coupons;
  },
  [MutationTypes.SET_CART_COUPON_ID](state, couponId): void {
    state.couponId = couponId;
  },
  [MutationTypes.SET_CART_PROMOCODE](state, promocode): void {
    state.promocode = promocode;
  },
  [MutationTypes.SET_CART_PROMOCODE_ID](state, promocodeId): void {
    state.promocodeId = promocodeId;
  },
};
