import type { Module } from 'vuex';
import { state, type State } from './state';
import type { RootState } from '@/store';
import { getters } from './getters';
import { mutations } from './mutations';
import { actions } from './actions';

export type { State as UserState } from './state';
export type { Mutations as UserMutations } from './mutations';
export type { Actions as UserActions } from './actions';
export type { Getters as UserGetters } from './getters';

export const userModule: Module<State, RootState> = {
  state,
  getters,
  mutations,
  actions,
};
