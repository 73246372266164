import trim from 'lodash/trim';
import { Store } from 'vuex';
import type { RequestUrlAndHeaders, HttpClientError } from '@/api/http/types';
import { GetterTypes } from '@/store/constants/getter-types';
import { MutationTypes } from '@/store/constants/mutation-types';
import { getUser, removeUser } from '@/services/user.service';
import { useTokenRefresher } from './tokenRefresher';
import debounce from 'lodash/debounce';

export interface TokenService {
  isTokenExpired(): boolean;
  isTokenRefreshAllowed(url: string): boolean;
  refreshToken(config: RequestUrlAndHeaders): Promise<unknown>;
}

const isTokenRefreshAllowed = (url: string) =>
  !['auth/refresh', 'auth/login', 'auth/logout'].includes(trim(url, '/'));

export function useTokenService(
  store: Store<unknown>,
  onTokenExpired?: () => void,
): TokenService {
  const tokenRefresher = useTokenRefresher();

  const onTokenExpiredDebounced = onTokenExpired
    ? debounce(onTokenExpired, 5000, {
        leading: true,
        trailing: false,
      })
    : null;

  const isTokenExpired = () => {
    const tokenExpiresAt = store.getters[GetterTypes.TOKEN_EXPIRES_AT];

    return tokenExpiresAt !== null && tokenExpiresAt < new Date();
  };

  const refreshToken = async (
    config: RequestUrlAndHeaders,
  ): Promise<unknown> => {
    try {
      await tokenRefresher.refreshToken();

      const { tokenExpiresAt } = getUser();
      store.commit(MutationTypes.SET_TOKEN_EXPIRES_AT, tokenExpiresAt);
    } catch (e) {
      removeUser();
      store.commit(MutationTypes.LOGOUT);

      onTokenExpiredDebounced?.();

      return Promise.reject({
        noToast: true,
        ...(e as HttpClientError),
        config,
      });
    }
  };

  return {
    isTokenExpired,
    isTokenRefreshAllowed,
    refreshToken,
  };
}
