import type { GetterTree } from 'vuex';
import type { State } from './state';
import type { RootState } from '@/store';
import { GetterTypes } from '@/store/constants/getter-types';
import type { Message } from './types';

export interface Getters {
  [GetterTypes.MESSAGES](state: State): Message[];
  [GetterTypes.MESSAGES_COUNT](state: State): number;
}

export const getters: GetterTree<State, RootState> & Getters = {
  [GetterTypes.MESSAGES](state): Message[] {
    return state.messages;
  },
  [GetterTypes.MESSAGES_COUNT](state: State): number {
    return state.count;
  },
};
