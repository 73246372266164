import type { RouteRecordRaw } from 'vue-router';
import { CatalogPageConst } from '@/pages/catalog/types';

const authRoutes = {
  path: '/auth',
  meta: {
    authPage: true,
  },
  children: [
    {
      path: 'login',
      alias: '',
      name: 'LoginForm',
      component: () => import('@/pages/auth/LoginForm.vue'),
    },
    {
      path: 'register',
      name: 'RegisterForm',
      component: () => import('@/pages/auth/RegisterForm.vue'),
    },
    {
      path: 'forgot',
      name: 'ForgotPasswordForm',
      component: () => import('@/pages/auth/ForgotPasswordForm.vue'),
    },
    {
      path: '/reset-password/:resetToken',
      name: 'ResetPasswordForm',
      component: () => import('@/pages/auth/ResetPasswordForm.vue'),
    },
  ],
};

const profileRoutes = {
  path: '/profile',
  name: 'ProfilePage',
  component: () => import('@/pages/profile/ProfilePage.vue'),
  children: [
    {
      path: 'user',
      name: 'ProfileUserInfo',
      component: () =>
        import('@/pages/profile/views/ProfileUserInfo/ProfileUserInfo.vue'),
    },
    {
      path: 'orders',
      name: 'ProfileOrders',
      component: () =>
        import('@/pages/profile/views/ProfileOrders/ProfileOrders.vue'),
    },
    {
      path: 'orders/:orderKey',
      name: 'ProfileOrder',
      component: () =>
        import('@/pages/profile/views/ProfileOrder/ProfileOrder.vue'),
      props: true,
    },
    {
      path: 'coupons',
      name: 'ProfileCoupons',
      component: () =>
        import('@/pages/profile/views/ProfileCoupons/ProfileCoupons.vue'),
    },
    {
      path: 'messages',
      name: 'ProfileMessages',
      component: () => import('@/pages/profile/views/ProfileMessages.vue'),
    },
    {
      path: 'favourites',
      name: 'ProfileFavourites',
      component: () => import('@/pages/profile/views/ProfileFavourites.vue'),
    },
  ],
};

export const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'HomePage',
    component: () => import('@/pages/home/HomePage.vue'),
  },
  {
    path: '/login',
    redirect: '/auth/login',
  },
  {
    path: '/register',
    redirect: '/auth/register',
  },
  authRoutes,
  {
    path: '/email/verify/:userId(\\d+)/:emailHash',
    name: 'EmailConfirmPage',
    component: () => import('@/pages/auth/EmailConfirmPage.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  profileRoutes,
  {
    path: '/cart',
    name: 'CartPage',
    component: () => import('@/pages/cart/CartPage.vue'),
  },
  {
    path: '/checkout/done',
    name: 'DoneNotification',
    component: () => import('@/pages/checkout/DoneNotification.vue'),
  },
  {
    path: '/checkout/:section(.*)*',
    name: 'CheckoutPage',
    component: () => import('@/pages/checkout/CheckoutPage.vue'),
  },
  {
    path: '/catalog',
    name: 'CatalogPageWithFilters',
    component: () => import('@/pages/catalog/CatalogPageWithFilters.vue'),
    props: { pageType: CatalogPageConst.COMMON },
  },
  {
    path: '/catalog/collection/:collectionId(\\d+)/:latinName?',
    name: 'CollectionPage',
    component: () => import('@/pages/catalog/CatalogPage.vue'),
    props: { pageType: CatalogPageConst.COLLECTION },
  },
  {
    path: '/catalog/:customCatalogType',
    name: 'CatalogPage',
    component: () => import('@/pages/catalog/CatalogPage.vue'),
    props: { pageType: CatalogPageConst.CUSTOM },
  },
  {
    path: '/search',
    name: 'SearchPage',
    component: () => import('@/pages/catalog/CatalogPage.vue'),
    props: { pageType: CatalogPageConst.SEARCH },
  },
  {
    path: '/book/:id(\\d+)/:latinName?',
    name: 'BookPage',
    component: () => import('@/pages/book/BookPage.vue'),
  },
  {
    path: '/test',
    name: 'TestPage',
    component: () => import('@/pages/TestPage.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFoundPage',
    component: () => import('@/pages/NotFoundPage.vue'),
  },
];
