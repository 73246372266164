import { ref } from 'vue';
import { rIC } from '@/common/idle';

type Sanitize = (
  source: string,
  config: Record<string, object | boolean | string[] | undefined>,
) => string;

const sanitizePromise = new Promise<Sanitize>((resolve) =>
  rIC(() => {
    import('dompurify').then((dompurifyModule) =>
      resolve(dompurifyModule.default.sanitize),
    );
  }),
);

export function useSanitize() {
  const sanitize = ref<Sanitize>(() => '');

  sanitizePromise.then((_sanitize) => (sanitize.value = _sanitize));

  const sanitizeHtml = (content: string) =>
    sanitize.value(content, {
      USE_PROFILES: { html: true },
      IN_PLACE: true,
    });

  const sanitizeSvg = (content: string) =>
    sanitize.value(content, {
      USE_PROFILES: { svg: true, svgFilters: true },
      IN_PLACE: true,
    });

  return {
    sanitizeHtml,
    sanitizeSvg,
  };
}
