import type { BookCard } from '@/components/BookCard/types';
import type { Sort } from './components/Sort/types';
import { FilterNameConst } from './components/Filters/types/FilterName';
import type { FilterIdType } from './components/Filters/types/FilterId';
import type { FilterValueId } from './components/Filters/types/FilterValueId';

export interface BookCatalog {
  title: string;
  bookList: BookList;
  hasSort: boolean;
}

export interface BookList {
  items: BookCard[];
  totalCount: number;
  perPageCount: number;
}

export const CustomCatalog = {
  BESTSELLERS: 'bestsellers',
  DISCOUNTS: 'discounts',
  NOVELTY: 'novelty',
  AUDIO: 'audio',
  POPULAR: 'popular',
} as const;
export type CustomCatalogType =
  (typeof CustomCatalog)[keyof typeof CustomCatalog];

export const CatalogPageConst = {
  COMMON: 'common',
  CUSTOM: 'custom',
  COLLECTION: 'collection',
  SEARCH: 'search',
} as const;
export type CatalogPageType =
  (typeof CatalogPageConst)[keyof typeof CatalogPageConst];

type PublishDateFilterValue =
  | FilterValueId.greaterYear
  | FilterValueId.lastYear
  | FilterValueId.last3Months
  | FilterValueId.lastMonth
  | FilterValueId.onSaleSoon;
type FormatFilterValue =
  | FilterValueId.eBook
  | FilterValueId.audioBook
  | FilterValueId.paperBook;

export type FiltersParams = {
  [FilterNameConst.genres]?: string;
  [FilterNameConst.author]?: string;
  [FilterNameConst.publishDate]?: PublishDateFilterValue;
  [FilterNameConst.format]?: FormatFilterValue;
  [FilterNameConst.publisher]?: string;
};

export type MoreFiltersParams = {
  filterId: FilterIdType;
  count: number;
  offsetCount: number;
  activeFilterValues?: string | null;
  parentGenreId?: number;
};

export type SearchFiltersParams = {
  filterId: FilterIdType;
  searchText: string;
};

export type CatalogQueryParams = {
  sort?: Sort;
  page?: number;
} & FiltersParams;
