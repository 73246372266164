import { get, type HttpClientResponse } from '@/api/http';
import type { Suggestion } from '@/components/Header/components/HeaderSearch/types';
import type { Sort } from '@/pages/catalog/components/Sort/types';
import type { BookCatalog } from '@/pages/catalog/types';
import type { BookCatalogResponse } from '@/api/catalog.api';

type SuggestionsResponse = HttpClientResponse<Suggestion[]>;

export const search = async (
  sort: Sort,
  page: number,
  q: string,
): Promise<BookCatalogResponse> =>
  get<BookCatalog>('search', { sort, page, q });

export const getSuggestions = async (
  query: string,
): Promise<SuggestionsResponse> =>
  get<Suggestion[]>('search/suggestions', {
    q: query,
  });
