import type { MutationTree } from 'vuex';
import type { State } from './state';
import { MutationTypes } from '@/store/constants/mutation-types';
import type { Message } from './types';

export interface Mutations<S = State> {
  [MutationTypes.SET_MESSAGES](state: S, messages: Message[]): void;
  [MutationTypes.SET_MESSAGES_COUNT](state: S, count: number): void;
}

export const mutations: MutationTree<State> & Mutations = {
  [MutationTypes.SET_MESSAGES](state, messages): void {
    state.messages = messages;
  },
  [MutationTypes.SET_MESSAGES_COUNT](state, count): void {
    state.count = count;
  },
};
