import { ref } from 'vue';

const isActive = ref(false);

let promise: Promise<void> = Promise.resolve();
let resolve: (() => void) | null = null;

const setRouteTransitionActive = (active: boolean) => {
  isActive.value = active;

  if (active) {
    promise = new Promise((res) => (resolve = res));
  } else {
    resolve?.();
    resolve = null;
  }
};

const routeTransition = () => promise;

export {
  isActive as routeTransitionActive,
  setRouteTransitionActive,
  routeTransition,
};
