import type { GetterTree } from 'vuex';
import type { State } from './state';
import type { RootState } from '@/store';
import { GetterTypes } from '@/store/constants/getter-types';
import type { Book } from '@/pages/book/types';

export interface Getters {
  [GetterTypes.FAVOURITE_BOOKS](state: State): Book[];
  [GetterTypes.FAVOURITES_COUNT](state: State): number;
  [GetterTypes.IS_FAVOURITE](state: State): (id: number) => boolean;
}

export const getters: GetterTree<State, RootState> & Getters = {
  [GetterTypes.FAVOURITE_BOOKS](state): Book[] {
    return state.books;
  },
  [GetterTypes.FAVOURITES_COUNT](state): number {
    return state.bookIds.length;
  },
  [GetterTypes.IS_FAVOURITE]: (state) => (bookId) => {
    return state.bookIds.includes(bookId);
  },
};
