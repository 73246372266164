const { body, documentElement } = document;
let scrollTop = 0;

export function disableScrolling() {
  if (body.classList.contains('no-scroll')) {
    return;
  }

  scrollTop = documentElement.scrollTop;
  body.style.top = `-${scrollTop}px`;
  body.classList.add('no-scroll');
}

export function enableScrolling() {
  if (!body.classList.contains('no-scroll')) {
    return;
  }

  body.classList.remove('no-scroll');
  documentElement.scrollTop = scrollTop;
  body.style.removeProperty('top');
}
