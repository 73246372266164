import { now } from './lib/now';

const supportsRequestIdleCallback = typeof requestIdleCallback === 'function';

/**
 * A minimal shim of the native IdleDeadline class.
 */
class IdleDeadline {
  constructor(private initTime: number) {}

  get didTimeout() {
    return false;
  }

  timeRemaining() {
    return Math.max(0, 50 - (now() - this.initTime));
  }
}

/**
 * A minimal shim for the requestIdleCallback function. This accepts a
 * callback function and runs it at the next idle period, passing in an
 * object with a `timeRemaining()` method.
 */
const requestIdleCallbackShim = (
  callback: (deadline: IdleDeadline) => void,
) => {
  const deadline = new IdleDeadline(now());

  return setTimeout(() => callback(deadline), 0);
};

/**
 * A minimal shim for the  cancelIdleCallback function. This accepts a
 * handle identifying the idle callback to cancel.
 */
const cancelIdleCallbackShim = (handle: number) => {
  clearTimeout(handle);
};

export const rIC = supportsRequestIdleCallback
  ? requestIdleCallback
  : requestIdleCallbackShim;

export const cIC = supportsRequestIdleCallback
  ? cancelIdleCallback
  : cancelIdleCallbackShim;
