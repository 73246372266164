import type {
  AddedToCartEvent,
  CountChangedInCartEvent,
  FavouritesEvent,
  RemovedFromCartEvent,
  UserInfoEvent,
} from './init/handlers/echo/types';
import type {
  FavouritesPayload,
  AddedToCartPayload,
  CountChangedInCartPayload,
  RemovedFromCartPayload,
} from './init/handlers/bc/types';

export const BroadcasterMessage = {
  FAVOURITES: 'FavouritesChanged',
  ADDED_TO_CART: 'AddedToCart',
  REMOVED_FROM_CART: 'RemovedFromCart',
  COUNT_CHANGED_IN_CART: 'CountChangedInCart',
  USER_INFO: 'UserInfoChanged',
  ORDER_CREATED: 'OrderCreated',
} as const;

export type BroadcasterMessageType =
  (typeof BroadcasterMessage)[keyof typeof BroadcasterMessage];

export type EchoMessageHandler =
  | ((event: FavouritesEvent) => void)
  | ((event: AddedToCartEvent) => void)
  | ((event: RemovedFromCartEvent) => void)
  | ((event: CountChangedInCartEvent) => void)
  | ((event: UserInfoEvent) => void)
  | (() => void);

export type BroadcastChannelMessageHandler =
  | ((payload: FavouritesPayload) => void)
  | ((payload: AddedToCartPayload) => void)
  | ((payload: RemovedFromCartPayload) => void)
  | ((payload: CountChangedInCartPayload) => void)
  | (() => void);
