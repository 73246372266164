import { ref } from 'vue';
import type { ConfigType, OptionType, Dayjs, PluginFunc } from 'dayjs';
import { IdleQueue } from '@/common/idle';

const LT = 'HH:mm';
const L = 'DD.MM.YYYY';

const weekdays = {
  standalone:
    'воскресенье_понедельник_вторник_среда_четверг_пятница_суббота'.split('_'),
  format: 'воскресенье_понедельник_вторник_среду_четверг_пятницу_субботу'.split(
    '_',
  ),
  isFormat: /\[ ?[Вв] ?(?:прошлую|следующую|эту)? ?] ?dddd/,
};

function formatWeekday(template: string, target: Dayjs) {
  if (template.match(weekdays.isFormat)) {
    return template.replace('dddd', weekdays.format[target.day()]);
  }
  return template.replace('dddd', weekdays.standalone[target.day()]);
}

function buildNextWeekTemplate(target: Dayjs, now: Dayjs): string {
  if (now.week() !== target.week()) {
    switch (target.day()) {
      case 0:
        return `[В следующее] dddd, [в] ${LT}`;
      case 1:
      case 2:
      case 4:
        return `[В следующий] dddd, [в] ${LT}`;
      case 3:
      case 5:
      case 6:
        return `[В следующую] dddd, [в] ${LT}`;
    }
  }

  return buildOtherWeekTemplate(target);
}

function buildLastWeekTemplate(target: Dayjs, now: Dayjs): string {
  if (now.week() !== target.week()) {
    switch (target.day()) {
      case 0:
        return `[В прошлое] dddd, [в] ${LT}`;
      case 1:
      case 2:
      case 4:
        return `[В прошлый] dddd, [в] ${LT}`;
      case 3:
      case 5:
      case 6:
        return `[В прошлую] dddd, [в] ${LT}`;
    }
  }

  return buildOtherWeekTemplate(target);
}

function buildOtherWeekTemplate(target: Dayjs): string {
  if (target.day() === 2) {
    return `[Во] dddd, [в] ${LT}`;
  }
  return `[В] dddd, [в] ${LT}`;
}

function yesterdayMidnight(): Date {
  const date = new Date();
  date.setDate(date.getDate() - 1);
  date.setHours(0, 0, 0, 0);

  return date;
}

type DayjsFn = (
  date?: ConfigType,
  format?: OptionType,
  locale?: string,
  strict?: boolean,
) => Dayjs;

type DayjsObj = {
  locale: (preset?: string) => string;
  extend<T = unknown>(plugin: PluginFunc<T>, option?: T): Dayjs;
  updateLocale(
    localeName: string,
    customConfig: Record<string, unknown>,
  ): Record<string, unknown>;
};

type DayjsType = DayjsFn & DayjsObj;

let resolveDayjs: ((value: DayjsFn | PromiseLike<DayjsFn>) => void) | null =
  null;
const dayjsPromise = new Promise<DayjsFn>(
  (resolve) => (resolveDayjs = resolve),
);

const queue = new IdleQueue();

const initCalendar = (_dayjs: DayjsType) => {
  _dayjs.updateLocale('ru', {
    calendar: {
      sameDay: `[Сегодня, в] ${LT}`,
      nextDay: `[Завтра, в] ${LT}`,
      lastDay: `[Вчера, в] ${LT}`,
      nextWeek: function (this: Dayjs, now: Dayjs) {
        return this.format(
          formatWeekday(buildNextWeekTemplate(this, now), this),
        );
      },
      lastWeek: function (this: Dayjs, now: Dayjs) {
        return this.format(
          formatWeekday(buildLastWeekTemplate(this, now), this),
        );
      },
      sameElse: L,
    },
  });
};

const initDayjs = (_dayjs: DayjsType) => {
  queue.pushTask(() =>
    import('dayjs/locale/ru').then(() => _dayjs.locale('ru')),
  );
  queue.pushTask(() =>
    import('dayjs/plugin/updateLocale').then((module) =>
      _dayjs.extend(module.default),
    ),
  );
  queue.pushTask(() =>
    import('dayjs/plugin/weekOfYear').then((module) =>
      _dayjs.extend(module.default),
    ),
  );
  queue.pushTask(() =>
    import('dayjs/plugin/calendar').then((module) => {
      _dayjs.extend(module.default);
      queue.pushTask(() => initCalendar(_dayjs));
      queue.pushTask(() => resolveDayjs?.(_dayjs));
    }),
  );
};

const emptyProxy = () =>
  new Proxy<Dayjs>({} as Dayjs, {
    get: () => () => {
      if (queue.hasPendingTasks()) {
        queue.runTasksImmediately();
      }
      return '';
    },
    set: () => true,
  });

queue.pushTask(() =>
  import('dayjs').then((dayjsModule) => initDayjs(dayjsModule.default)),
);

export function useDate() {
  const dayjs = ref<DayjsFn>(emptyProxy);

  dayjsPromise.then((_dayjs) => (dayjs.value = _dayjs));

  const isToday = (date: string | number) =>
    new Date(date).setHours(0, 0, 0, 0) == new Date().setHours(0, 0, 0, 0);

  const isYesterdayOrAfter = (date: string | number) =>
    new Date(date) >= yesterdayMidnight();

  const formatDate = (date: ConfigType) =>
    dayjs.value(date).format('DD.MM.YYYY');

  const formatMachineDateTime = (date: ConfigType) =>
    dayjs.value(date).format('YYYY-MM-DD HH:mm');

  const formatHumanDateTime = (date: ConfigType) =>
    dayjs.value(date).format('DD.MM.YYYY HH:mm');

  const formatRelativeDateTime = (date: ConfigType) =>
    dayjs.value(date).calendar();

  return {
    isToday,
    isYesterdayOrAfter,
    formatDate,
    formatMachineDateTime,
    formatHumanDateTime,
    formatRelativeDateTime,
  };
}
