import type { ActionContext, ActionTree } from 'vuex';
import type { State } from './state';
import type { AugmentedCommit, RootState } from '@/store';
import { ActionTypes } from '@/store/constants/action-types';
import { MutationTypes } from '@/store/constants/mutation-types';
import { getUserMessages, type MessagesResponse } from '@/api/messages.api';
import { setCount } from '@/services/messages.service';

type AugmentedActionContext = {
  commit: AugmentedCommit;
} & Omit<ActionContext<State, RootState>, 'commit'>;

export interface Actions {
  [ActionTypes.LOAD_MESSAGES](
    context: AugmentedActionContext,
  ): Promise<MessagesResponse>;
}

const MESSAGES_COUNT = 10;

export const actions: ActionTree<State, RootState> & Actions = {
  async [ActionTypes.LOAD_MESSAGES]({ commit }): Promise<MessagesResponse> {
    const [error, messages] = await getUserMessages(MESSAGES_COUNT);

    if (!error && messages) {
      commit(MutationTypes.SET_MESSAGES, messages.items);
      commit(MutationTypes.SET_MESSAGES_COUNT, messages.totalCount);

      setCount(messages.totalCount);
    }

    return [error, messages] as MessagesResponse;
  },
};
