class MockLocalStorage {
  private store: { [key: string]: string } = {};

  clear() {
    this.store = {};
  }

  getItem(key: string): string | null {
    return this.store[key] || null;
  }

  key(index: number): string | null {
    const keys = Object.keys(this.store);
    return keys[index] || null;
  }

  get length() {
    return Object.keys(this.store).length;
  }

  removeItem(key: string) {
    delete this.store[key];
  }

  setItem(key: string, value: string) {
    this.store[key] = value;
  }
}

let initialized = false;

export function initLocalStorage() {
  if (initialized) {
    return;
  }
  initialized = true;

  if (typeof window !== 'undefined' && window.localStorage) {
    return;
  }

  const globalObject =
    typeof window !== 'undefined'
      ? window
      : // @ts-expect-error: error TS2304: Cannot find name 'global'.
        typeof global !== 'undefined'
        ? // @ts-expect-error: error TS2304: Cannot find name 'global'.
          global
        : globalThis;

  Object.defineProperty(globalObject, 'localStorage', {
    value: new MockLocalStorage(),
    writable: true,
  });
}
