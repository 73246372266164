import { state, type State } from './state';
import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';
import type { Module } from 'vuex';
import type { RootState } from '@/store';

export type { State as CartState } from './state';
export type { Mutations as CartMutations } from './mutations';
export type { Actions as CartActions } from './actions';
export type { Getters as CartGetters } from './getters';

export const cartModule: Module<State, RootState> = {
  state,
  getters,
  mutations,
  actions,
};
