import type { UserActions } from '@/store/modules/user';
import type { OrdersActions } from '@/store/modules/orders';
import type { FavouritesActions } from '@/store/modules/favourites';
import type { MessagesActions } from '@/store/modules/messages';
import type { CartActions } from '@/store/modules/cart';
import { ActionTypes } from '@/store/constants/action-types';
import type { AugmentedCommit, RootState } from '@/store/index';
import type { ActionContext, ActionTree } from 'vuex';
import type { State } from './state';
import { getCommonStateData } from '@/api/common.api';
import { MutationTypes } from '@/store/constants/mutation-types';
import { setCount } from '@/services/messages.service';
import { setBookIds } from '@/services/favourites.service';
import { setCart } from '@/services/cart.service';
import type { HttpClientError, HttpClientResponse } from '@/api/http';
import { GetterTypes } from '@/store/constants/getter-types';
import { toBookCounts } from '@/store/utils';

type AugmentedActionContext = {
  commit: AugmentedCommit;
} & Omit<ActionContext<State, RootState>, 'commit'>;

export interface Actions
  extends RootActions,
    UserActions,
    OrdersActions,
    FavouritesActions,
    MessagesActions,
    CartActions {}

export interface RootActions {
  [ActionTypes.LOAD_COMMON_STATE](
    context: AugmentedActionContext,
  ): Promise<HttpClientResponse>;
  [ActionTypes.DISABLE_TRANSITIONS](context: AugmentedActionContext): void;
  [ActionTypes.ENABLE_TRANSITIONS](context: AugmentedActionContext): void;
}

export const actions: ActionTree<State, RootState> & RootActions = {
  async [ActionTypes.LOAD_COMMON_STATE]({
    rootState,
    rootGetters,
    commit,
    dispatch,
  }): Promise<HttpClientResponse> {
    const params = {
      book_counts: toBookCounts(rootState.cart.cartInfo),
      favourite_book_ids: rootState.favourites.bookIds.join(','),
      promocode_id: rootState.cart.promocodeId,
    };

    const [error, response] = await getCommonStateData(params);
    if (error || !response) {
      return [error || new Error('Failed to get common state data')];
    }

    commit(MutationTypes.SET_MESSAGES_COUNT, response.messagesCount);
    setCount(response.messagesCount);

    const favouriteBookIds = response.favouriteBookIds.map((id) => +id);
    commit(MutationTypes.SET_FAVOURITE_IDS, favouriteBookIds);
    setBookIds(favouriteBookIds);

    commit(MutationTypes.SET_CART_INFO, response.cartInfo ?? {});
    setCart(response.cartInfo ?? {});

    if (rootGetters[GetterTypes.LOGGED_IN]) {
      try {
        dispatch(ActionTypes.SET_CART_COUPONS, response.coupons);
      } catch (e) {
        return [e as HttpClientError];
      }
    }

    if (
      !rootGetters[GetterTypes.CART_APPLIED_COUPON] &&
      rootState.cart.promocodeId
    ) {
      try {
        dispatch(ActionTypes.SET_CART_PROMOCODE, response.promocode);
      } catch (e) {
        return [e as HttpClientError];
      }
    }

    return [null, null];
  },
  [ActionTypes.DISABLE_TRANSITIONS]({ commit }): void {
    commit(MutationTypes.SET_NO_TRANSITIONS, true);
  },
  [ActionTypes.ENABLE_TRANSITIONS]({ commit }): void {
    commit(MutationTypes.SET_NO_TRANSITIONS, false);
  },
};
