export const ImageSlot = {
  collection: 1,
  bestseller: 2,
  exposition: 3,
  catalog: 4,
  cover: 5,
  favourite: 6,
  orderBook: 7,
  cart: 8,
  confirm: 9,
  autocomplete: 10,
};
