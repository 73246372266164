import { getBookIds } from '@/services/favourites.service';
import type { Book } from '@/pages/book/types';

export type State = {
  bookIds: number[];
  books: Book[];
};

export const state = {
  bookIds: getBookIds(),
  books: [],
} as State;
