import type {
  BeforeRequestHook,
  ErrorResponseData,
  HttpClientError,
  HttpClientResponse,
  RequestConfig,
  RequestData,
  ResponseErrorHook,
} from '../types';

const emptyResponseErrorHook: ResponseErrorHook = async (e) => [e];

export interface HttpClientInterface {
  addBeforeRequestHook(hook: BeforeRequestHook): this;
  setResponseErrorHook(hook: ResponseErrorHook): this;
  request<T = unknown>(config: RequestConfig): Promise<HttpClientResponse<T>>;
}

let isOnline = true;

window.addEventListener('online', () => (isOnline = true));
window.addEventListener('offline', () => (isOnline = false));

export abstract class HttpClientBase implements HttpClientInterface {
  protected beforeRequestHooks: BeforeRequestHook[] = [];
  protected responseErrorHook: ResponseErrorHook = emptyResponseErrorHook;

  public addBeforeRequestHook(hook: BeforeRequestHook) {
    if (typeof hook === 'function' && !this.beforeRequestHooks.includes(hook)) {
      this.beforeRequestHooks.push(hook);
    }

    return this;
  }

  public setResponseErrorHook(hook: ResponseErrorHook) {
    if (typeof hook !== 'function') {
      hook = emptyResponseErrorHook;
    }

    this.responseErrorHook = hook;

    return this;
  }

  private transformErrorData<T>(
    e: HttpClientError<T> | null,
  ): HttpClientError<T> | null {
    if (typeof e?.response?.data === 'string') {
      (e.response.data as unknown as { message: string }) = {
        message: e.response.data,
      };
    }

    return e;
  }

  private async handleBeforeRequestHooks<D = RequestData>(
    config: RequestConfig<D>,
  ) {
    config.headers = config.headers || {};

    for (const handler of this.beforeRequestHooks) {
      await handler(config);
    }
  }

  protected abstract executeRequest<D = RequestData, R = unknown>(
    config: RequestConfig<D>,
  ): Promise<HttpClientResponse<R>>;

  public async request<D = RequestData, R = unknown>(
    config: RequestConfig<D>,
  ): Promise<HttpClientResponse<R>> {
    try {
      await this.handleBeforeRequestHooks<D>(config);
    } catch (e) {
      return [
        this.transformErrorData<ErrorResponseData>(e as HttpClientError)!,
      ];
    }

    try {
      return await this.executeRequest<D, R>(config);
    } catch (e) {
      const [error, res] = await this.responseErrorHook(e as HttpClientError);

      if (error && !isOnline) {
        error.noToast = true;
      }

      return [
        this.transformErrorData(error as HttpClientError),
        res,
      ] as HttpClientResponse<R>;
    }
  }
}
