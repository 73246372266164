import type { ActionContext, ActionTree } from 'vuex';
import type { State } from './state';
import type { AugmentedCommit, RootState } from '@/store';
import { ActionTypes } from '@/store/constants/action-types';
import { MutationTypes } from '@/store/constants/mutation-types';
import {
  setCart,
  getCart,
  setCouponId,
  setPromocodeId,
  setCartEntry,
  remove,
  setCount,
  clearCart,
} from '@/services/cart.service';
import {
  getCart as apiGetCart,
  addToCart,
  removeFromCart,
  type CartResponse,
} from '@/api/cart.api';
import { GetterTypes } from '@/store/constants/getter-types';
import {
  findPromocodeByCode,
  type PromocodeResponse,
} from '@/api/promocodes.api';
import type { OrderBook } from '@/components/OrderBook/types';
import type { HttpClientResponse } from '@/api/http';
import type { CartInfo, Coupon, Promocode } from './types';
import { toBookCounts } from '@/store/utils';
import {
  apiSetCartBookCount,
  broadcastAddToCart,
  broadcastRemoveFromCart,
  broadcastSetCartBookCount,
  cartBooksToCartInfo,
  rollbackOnError,
  syncCartBooksWithCartInfo,
} from './utils';

export type AugmentedActionContext = {
  commit: AugmentedCommit;
} & Omit<ActionContext<State, RootState>, 'commit'>;

export interface Actions {
  [ActionTypes.LOAD_CART](
    context: AugmentedActionContext,
  ): Promise<CartResponse>;
  [ActionTypes.SET_CART](
    context: AugmentedActionContext,
    books: OrderBook[],
  ): void;
  [ActionTypes.SET_CART_INFO](
    context: AugmentedActionContext,
    cartInfo: CartInfo,
  ): void;
  [ActionTypes.ADD_TO_CART_STORE](
    context: AugmentedActionContext,
    book: OrderBook,
  ): void;
  [ActionTypes.ADD_TO_CART](
    context: AugmentedActionContext,
    book: OrderBook,
  ): Promise<HttpClientResponse>;
  [ActionTypes.REMOVE_FROM_CART_STORE](
    context: AugmentedActionContext,
    bookVariationId: number,
  ): void;
  [ActionTypes.REMOVE_FROM_CART](
    context: AugmentedActionContext,
    bookVariationId: number,
  ): Promise<HttpClientResponse>;
  [ActionTypes.SET_CART_STORE_BOOK_COUNT](
    context: AugmentedActionContext,
    payload: { bookVariationId: number; count: number },
  ): void;
  [ActionTypes.SET_CART_BOOK_COUNT](
    context: AugmentedActionContext,
    payload: { bookVariationId: number; count: number },
  ): Promise<HttpClientResponse>;
  [ActionTypes.SET_CART_COUPONS](
    context: AugmentedActionContext,
    coupons?: Coupon[] | null,
  ): void;
  [ActionTypes.SET_CART_PROMOCODE](
    context: AugmentedActionContext,
    promocode?: Promocode | null,
  ): void;
  [ActionTypes.APPLY_COUPON](
    context: AugmentedActionContext,
    couponId: number,
  ): void;
  [ActionTypes.APPLY_PROMOCODE](
    context: AugmentedActionContext,
    code: string,
  ): Promise<PromocodeResponse>;
  [ActionTypes.CANCEL_BONUS](context: AugmentedActionContext): Promise<void>;
  [ActionTypes.CLEAR_CART](context: AugmentedActionContext): Promise<void>;
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [ActionTypes.LOAD_CART]({
    state,
    rootGetters,
    commit,
    dispatch,
  }): Promise<CartResponse> {
    if (state.loadLock) {
      return [null, []];
    }

    commit(MutationTypes.SET_CART_LOAD_LOCK);

    let error: Error | null = null;
    let data: OrderBook[] | undefined;

    if (
      rootGetters[GetterTypes.LOGGED_IN] ||
      Object.keys(state.cartInfo).length > 0
    ) {
      [error, data] = await apiGetCart(toBookCounts(state.cartInfo));

      if (data) {
        dispatch(ActionTypes.SET_CART, data);
      }
    }

    commit(MutationTypes.SET_CART_LOADED);

    setTimeout(() => {
      commit(MutationTypes.SET_CART_LOAD_LOCK, false);
    }, 5000);

    return [error, data] as CartResponse;
  },
  [ActionTypes.SET_CART]({ commit }, books): void {
    setCart(cartBooksToCartInfo(books));

    commit(MutationTypes.SET_CART_INFO, getCart());
    commit(MutationTypes.SET_CART_BOOKS, books);
  },
  [ActionTypes.SET_CART_INFO]({ state, commit }, cartInfo): void {
    setCart(cartInfo);
    commit(MutationTypes.SET_CART_INFO, getCart());

    commit(
      MutationTypes.SET_CART_BOOKS,
      syncCartBooksWithCartInfo(state.cartBooks, cartInfo),
    );
  },
  [ActionTypes.SET_CART_COUPONS]({ state, getters, commit }, coupons) {
    commit(MutationTypes.SET_CART_COUPONS, coupons || []);

    if (state.couponId && !getters[GetterTypes.CART_APPLIED_COUPON]) {
      setCouponId(null);
      commit(MutationTypes.SET_CART_COUPON_ID, null);

      throw Error('Истек срок действия купона');
    }
  },
  [ActionTypes.SET_CART_PROMOCODE]({ commit }, promocode) {
    if (promocode) {
      commit(MutationTypes.SET_CART_PROMOCODE, promocode);
    } else {
      setPromocodeId(null);
      commit(MutationTypes.SET_CART_PROMOCODE_ID, null);

      throw Error('Истек срок действия промокода');
    }
  },
  [ActionTypes.ADD_TO_CART_STORE](context, book) {
    const bookVariationId = book.variations[0].id;

    setCartEntry(bookVariationId, {
      count: book.count,
      price: book.price,
      basePrice: book.basePrice,
    });
    context.commit(MutationTypes.SET_CART_INFO, getCart());

    const foundCartBook =
      context.getters[GetterTypes.CART_BOOK](bookVariationId);

    if (foundCartBook) {
      context.commit(MutationTypes.SET_CART_BOOK_COUNT, {
        bookVariationId,
        count: book.count,
      });
    } else {
      context.commit(MutationTypes.SET_CART_BOOKS, [
        ...context.getters[GetterTypes.CART_BOOKS],
        book,
      ]);
    }
  },
  async [ActionTypes.ADD_TO_CART](context, book): Promise<HttpClientResponse> {
    const bookVariationId = book.variations[0].id;

    const [error] = await rollbackOnError(context, async () => {
      await context.dispatch(ActionTypes.ADD_TO_CART_STORE, book);

      if (context.rootGetters[GetterTypes.LOGGED_IN]) {
        return await addToCart(bookVariationId);
      }

      broadcastAddToCart(context.state.cartInfo, book);

      return [null, null];
    });

    return error
      ? [new Error('Ошибка при добавлении в корзину.')]
      : [null, null];
  },
  [ActionTypes.REMOVE_FROM_CART_STORE](context, bookVariationId) {
    remove(bookVariationId);
    context.commit(MutationTypes.SET_CART_INFO, getCart());
    context.commit(MutationTypes.REMOVE_FROM_CART, bookVariationId);
  },
  async [ActionTypes.REMOVE_FROM_CART](
    context,
    bookVariationId,
  ): Promise<HttpClientResponse> {
    const [error] = await rollbackOnError(context, async () => {
      await context.dispatch(
        ActionTypes.REMOVE_FROM_CART_STORE,
        bookVariationId,
      );

      if (context.rootGetters[GetterTypes.LOGGED_IN]) {
        return await removeFromCart(bookVariationId);
      }

      broadcastRemoveFromCart(context.state.cartInfo, bookVariationId);

      return [null, null];
    });

    return error
      ? [new Error('Не удалось удалить книгу из корзины.')]
      : [null, null];
  },
  [ActionTypes.SET_CART_STORE_BOOK_COUNT](context, { bookVariationId, count }) {
    setCount(bookVariationId, count);
    context.commit(MutationTypes.SET_CART_INFO, getCart());
    context.commit(MutationTypes.SET_CART_BOOK_COUNT, {
      bookVariationId,
      count,
    });
  },
  async [ActionTypes.SET_CART_BOOK_COUNT](
    context,
    { bookVariationId, count },
  ): Promise<HttpClientResponse> {
    if (count <= 0) {
      return context.dispatch(ActionTypes.REMOVE_FROM_CART, bookVariationId);
    }

    const [error] = await rollbackOnError(context, async () => {
      await context.dispatch(ActionTypes.SET_CART_STORE_BOOK_COUNT, {
        bookVariationId,
        count,
      });

      const [err, apiCount] = await apiSetCartBookCount(
        context,
        bookVariationId,
        count,
      );

      broadcastSetCartBookCount(
        context.state.cartInfo,
        bookVariationId,
        apiCount,
      );

      return [err, apiCount];
    });

    return error
      ? [new Error('Не удалось изменить количество книг в корзине.')]
      : [null, null];
  },
  [ActionTypes.APPLY_COUPON]({ commit }, couponId): void {
    setPromocodeId(null);
    commit(MutationTypes.SET_CART_PROMOCODE_ID, null);

    commit(MutationTypes.SET_CART_COUPON_ID, couponId);
    setCouponId(couponId);
  },
  async [ActionTypes.APPLY_PROMOCODE](
    { commit },
    code,
  ): Promise<PromocodeResponse> {
    const [error, promocode] = await findPromocodeByCode(code);

    if (!error && promocode) {
      setCouponId(null);
      commit(MutationTypes.SET_CART_COUPON_ID, null);

      commit(MutationTypes.SET_CART_PROMOCODE, promocode);

      setPromocodeId(promocode.id);
      commit(MutationTypes.SET_CART_PROMOCODE_ID, promocode.id);
    }

    return [error, promocode] as PromocodeResponse;
  },
  async [ActionTypes.CANCEL_BONUS]({ commit }): Promise<void> {
    commit(MutationTypes.SET_CART_COUPON_ID, null);
    commit(MutationTypes.SET_CART_PROMOCODE_ID, null);
    commit(MutationTypes.SET_CART_PROMOCODE, null);
    setCouponId(null);
    setPromocodeId(null);
  },
  async [ActionTypes.CLEAR_CART]({ commit }): Promise<void> {
    clearCart();

    commit(MutationTypes.SET_CART_INFO, {});
    commit(MutationTypes.SET_CART_BOOKS, []);
    commit(MutationTypes.SET_CART_LOADED, false);
    commit(MutationTypes.SET_CART_LOAD_LOCK, false);
    commit(MutationTypes.SET_CART_PROMOCODE_ID, null);
    commit(MutationTypes.SET_CART_PROMOCODE, null);
    commit(MutationTypes.SET_CART_COUPON_ID, null);
    commit(MutationTypes.SET_CART_COUPONS, []);
  },
};
