import type { CartInfo, CartInfoEntry } from '@/store/modules/cart/types';
import {
  getStorageItem,
  removeStorageItem,
  setStorageItem,
} from '@/services/storage';

export const getCart = (): CartInfo => getStorageItem('cart') || {};

export const setCart = (cartInfo: CartInfo) => setStorageItem('cart', cartInfo);

export const clearCart = (): void => {
  removeStorageItem('cart');
  removeStorageItem('promocodeId');
  removeStorageItem('couponId');
};

export const setCartEntry = (bookVariationId: number, entry: CartInfoEntry) => {
  const cart = getCart();
  cart[bookVariationId] = entry;
  setCart(cart);
};

export const remove = (bookVariationId: number) => {
  const cart = getCart();
  delete cart[bookVariationId];
  setCart(cart);
};

export const getCount = (bookVariationId: number): number =>
  getCart()[bookVariationId]?.count || 0;

export const setCount = (bookVariationId: number, count: number) => {
  const cart = getCart();

  if (cart[bookVariationId]) {
    cart[bookVariationId].count = count;
  }

  setCart(cart);
};

export const getPromocodeId = (): number | null =>
  getStorageItem('promocodeId');

export const setPromocodeId = (promocodeId: number | null) => {
  if (promocodeId) {
    setStorageItem('promocodeId', promocodeId);
  } else {
    removeStorageItem('promocodeId');
  }
};

export const getCouponId = (): number | null => getStorageItem('couponId');

export const setCouponId = (couponId: number | null) => {
  if (couponId) {
    setStorageItem('couponId', couponId);
  } else {
    removeStorageItem('couponId');
  }
};
