import { state, type State } from './state';
import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';
import type { Module } from 'vuex';
import type { RootState } from '@/store';

export type { State as FavouritesState } from './state';
export type { Mutations as FavouritesMutations } from './mutations';
export type { Actions as FavouritesActions } from './actions';
export type { Getters as FavouritesGetters } from './getters';

export const favouritesModule: Module<State, RootState> = {
  state,
  getters,
  mutations,
  actions,
};
