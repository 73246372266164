import type { App } from 'vue';

export default {
  install(app: App) {
    app.config.globalProperties.range = (
      start: number,
      end: number,
    ): number[] => {
      return Array(Math.max(end - start + 1, 0))
        .fill(0)
        .map((_: number, idx: number) => start + idx);
    };
  },
};
