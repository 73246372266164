import type {
  RouteLocationNormalized,
  RouteLocationNormalizedLoaded,
} from 'vue-router';
import { routeTransition } from './transitions/state';

type RouterScrollToOptions = ScrollToOptions & { el?: string | Element };

const pageName = (
  route: RouteLocationNormalized | RouteLocationNormalizedLoaded,
) => {
  const pageName = route.matched[0]?.name;

  return typeof pageName === 'string' ? pageName : null;
};

const noScrollCases = {
  '*': ['CheckoutPage', 'ProfilePage'],
  CatalogPage: ['CatalogPage', 'CatalogPageWithFilters'],
  CatalogPageWithFilters: ['CatalogPage', 'CatalogPageWithFilters'],
} as Record<string, string[]>;

const noScroll = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalizedLoaded,
) => {
  const fromPage = pageName(from);
  const toPage = pageName(to);

  if (!toPage) {
    return false;
  }

  return (
    (fromPage && noScrollCases[fromPage]?.includes(toPage)) ||
    noScrollCases['*'].includes(toPage)
  );
};

const scrollToContainerPages = [
  'CatalogPage',
  'CatalogPageWithFilters',
  'BookPage',
  'CartPage',
];

const resolveScrollOptions = (
  to: RouteLocationNormalized,
): RouterScrollToOptions | Promise<RouterScrollToOptions> => {
  const toPage = pageName(to);

  if (toPage && scrollToContainerPages.includes(toPage)) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({
          el: 'main',
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
      }, 100);
    });
  }

  return {
    top: 0,
    left: 0,
    behavior: 'smooth',
  };
};

export async function scrollBehavior(
  to: RouteLocationNormalized,
  from: RouteLocationNormalizedLoaded,
  savedPosition: ScrollToOptions | null,
) {
  if (savedPosition) {
    return savedPosition;
  }

  if (noScroll(to, from)) {
    return {};
  }

  await routeTransition();

  return resolveScrollOptions(to);
}
