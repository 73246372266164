const events = [
  'mousemove',
  'scroll',
  'keydown',
  'click',
  'touchstart',
  'wheel',
];

export function onUserActivity(listener: (evt: Event) => void) {
  let invokeCounter = 0;

  const handler = (evt: Event) => {
    if (++invokeCounter > 1) {
      return;
    }

    events.forEach((event) => document.removeEventListener(event, handler));

    listener(evt);
  };

  events.forEach((event) => document.addEventListener(event, handler));
}
