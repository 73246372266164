export type CartInfo = {
  [variationId: number]: CartInfoEntry;
};

export type CartInfoEntry = {
  count: number;
  price: number;
  basePrice?: number | null;
};

export enum EffectEnum {
  PRICE = 1,
  DELIVERY_PRICE = 2,
  DELIVERY_FREE = 3,
  BUY_PAPER_THEN_AUDIO_FREE = 4,
}
export type EffectType = `${EffectEnum}`;

export interface Bonus {
  id: number;
  name: string;
  effect: EffectEnum;
  value?: number;
  expiresAt: string | number;
}

export interface Coupon extends Bonus {
  imgUrl?: string;
}

export interface Promocode extends Bonus {
  code?: string;
}
