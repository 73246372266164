import type { ActionContext, ActionTree } from 'vuex';
import type { State } from './state';
import type {
  AugmentedCommit,
  AugmentedDispatch,
  RootState,
  Store,
} from '@/store';
import { ActionTypes } from '@/store/constants/action-types';
import { MutationTypes } from '@/store/constants/mutation-types';
import {
  login,
  logout,
  register,
  sendResetLink,
  resetPassword,
  type LoginResponse,
  type ResetResponse,
} from '@/api/auth.api';
import type {
  LoginUserData,
  SendPasswordResetData,
  RegisterUserData,
  ResetPasswordData,
  ChangePasswordData,
  UserInfoData,
} from './types';
import { setUser, removeUser, setUserInfo } from '@/services/user.service';
import {
  changePassword,
  changeUserInfo,
  getUserInfo,
  type UserInfoResponse,
} from '@/api/profile.api';
import type { SuccessStatusResponse } from '@/api/types';
import { broadcaster, BroadcasterMessage } from '@/broadcaster';

type AugmentedActionContext = {
  commit: AugmentedCommit;
} & Omit<ActionContext<State, RootState>, 'commit'>;

export interface Actions {
  [ActionTypes.LOGIN](
    context: AugmentedActionContext,
    payload: { userData: LoginUserData },
  ): Promise<LoginResponse>;
  [ActionTypes.LOGOUT](context: AugmentedActionContext): Promise<void>;
  [ActionTypes.REGISTER](
    context: AugmentedActionContext,
    payload: { userData: RegisterUserData; couponId?: number | null },
  ): Promise<SuccessStatusResponse>;
  [ActionTypes.SEND_PASSWORD_RESET](
    context: AugmentedActionContext,
    payload: SendPasswordResetData,
  ): Promise<ResetResponse>;
  [ActionTypes.RESET_PASSWORD](
    context: AugmentedActionContext,
    payload: ResetPasswordData,
  ): Promise<ResetResponse>;
  [ActionTypes.CHANGE_PASSWORD](
    context: AugmentedActionContext,
    payload: ChangePasswordData,
  ): Promise<SuccessStatusResponse>;
  [ActionTypes.CHANGE_USER_INFO](
    context: AugmentedActionContext,
    userInfo: UserInfoData,
  ): Promise<SuccessStatusResponse>;
  [ActionTypes.GET_USER_INFO](
    context: AugmentedActionContext,
  ): Promise<UserInfoResponse>;
}

function clearUser(commit: AugmentedCommit) {
  removeUser();

  commit(MutationTypes.LOGOUT);
}

function loadAfterLogin(dispatch: AugmentedDispatch) {
  setTimeout(() => {
    dispatch(ActionTypes.LOAD_COMMON_STATE);

    broadcaster
      .listen(BroadcasterMessage.FAVOURITES)
      .listen(BroadcasterMessage.ADDED_TO_CART)
      .listen(BroadcasterMessage.REMOVED_FROM_CART)
      .listen(BroadcasterMessage.COUNT_CHANGED_IN_CART)
      .listen(BroadcasterMessage.ORDER_CREATED)
      .listen(BroadcasterMessage.USER_INFO);
  }, 0);
}

async function loadOrdersModule(store: Store) {
  if (!store.hasModule('orders')) {
    const { ordersModule } = await import('@/store/modules/orders');
    store.registerModule('orders', ordersModule);
  }
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [ActionTypes.LOGIN](
    { commit, dispatch },
    { userData },
  ): Promise<LoginResponse> {
    commit(MutationTypes.LOGOUT);

    const [error, res] = await login(userData);

    if (res) {
      await loadOrdersModule(this as unknown as Store);

      const user = {
        tokenExpiresAt: res.expiresAt,
        ...res.user,
        id: +res.user.id,
      };

      setUser(user);

      commit(MutationTypes.LOGIN, user);

      dispatch(ActionTypes.CLEAR_CHECKOUT_DATA);

      loadAfterLogin(dispatch);
    }

    return [error, res] as LoginResponse;
  },
  async [ActionTypes.LOGOUT]({ commit, dispatch }): Promise<void> {
    await logout();

    clearUser(commit);

    loadOrdersModule(this as unknown as Store).then(() =>
      dispatch(ActionTypes.CLEAR_CHECKOUT_DATA),
    );
  },
  async [ActionTypes.REGISTER](
    { commit },
    { userData, couponId },
  ): Promise<SuccessStatusResponse> {
    commit(MutationTypes.LOGOUT);

    return await register(userData, couponId);
  },
  async [ActionTypes.SEND_PASSWORD_RESET](
    context,
    payload,
  ): Promise<ResetResponse> {
    return sendResetLink(payload);
  },
  async [ActionTypes.RESET_PASSWORD](context, payload): Promise<ResetResponse> {
    return resetPassword(payload);
  },
  async [ActionTypes.CHANGE_PASSWORD](
    context,
    payload,
  ): Promise<SuccessStatusResponse> {
    return changePassword(payload);
  },
  async [ActionTypes.CHANGE_USER_INFO](
    { commit },
    userInfo,
  ): Promise<SuccessStatusResponse> {
    const [error, response] = await changeUserInfo(userInfo);

    if (response?.success) {
      commit(MutationTypes.SET_USER_INFO, userInfo);
      setUserInfo(userInfo);
    }

    return [error, response] as SuccessStatusResponse;
  },
  async [ActionTypes.GET_USER_INFO]({ commit }): Promise<UserInfoResponse> {
    const [error, response] = await getUserInfo();

    if (response) {
      commit(MutationTypes.SET_USER_INFO, response);
      setUserInfo(response);
    }

    return [error, response] as UserInfoResponse;
  },
};
