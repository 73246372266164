import { phoneNumberMask } from '@/common/functions';

export default {
  updated(el: HTMLElement) {
    const inputEl = el.querySelector('input');
    if (inputEl && inputEl.value && inputEl !== document.activeElement) {
      const maskedValue = phoneNumberMask(inputEl.value);

      inputEl.value = maskedValue;
    }
  },
};
