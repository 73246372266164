import type { RequestUrlAndHeaders } from '@/api/http/types';
import type { TokenService } from '@/services/token/tokenService';

export const useRefreshTokenIfNeeded = (tokenService: TokenService) => {
  const needToRefreshToken = (url: string) =>
    tokenService.isTokenRefreshAllowed(url) && tokenService.isTokenExpired();

  return (config: RequestUrlAndHeaders) => {
    if (!needToRefreshToken(config.url || '')) {
      return;
    }

    tokenService.refreshToken(config);
  };
};
