export enum GetterTypes {
  LOGGED_IN = 'USER_LOGGED_IN',
  USER_ID = 'USER_USER_ID',
  USER_NAME = 'USER_USER_NAME',
  USER_INFO = 'USER_INFO',
  TOKEN_EXPIRES_AT = 'USER_TOKEN_EXPIRES_AT',

  IS_CHECKOUT_CLEAR = 'ORDERS_IS_CHECKOUT_CLEAR',
  CHECKOUT_STEP = 'ORDERS_CHECKOUT_STEP',
  CHECKOUT_NEXT_STEP = 'ORDERS_CHECKOUT_NEXT_STEP',
  CHECKOUT_PREVIOUS_STEP = 'ORDERS_CHECKOUT_PREVIOUS_STEP',
  CHECKOUT_RETURN_STEP = 'ORDERS_CHECKOUT_RETURN_STEP',
  CHECKOUT_STEP_INFO = 'ORDERS_CHECKOUT_STEP_INFO',
  CHECKOUT_DELIVERY_TYPE = 'ORDERS_CHECKOUT_DELIVERY_TYPE',
  CHECKOUT_DELIVERY_PICKPOINT = 'ORDERS_CHECKOUT_DELIVERY_PICKPOINT',
  CHECKOUT_DELIVERY_DATE = 'ORDERS_CHECKOUT_DELIVERY_DATE',
  CHECKOUT_DELIVERY_ADDRESS = 'ORDERS_CHECKOUT_DELIVERY_ADDRESS',
  CHECKOUT_DELIVERY_PRICE = 'ORDERS_CHECKOUT_DELIVERY_PRICE',
  CHECKOUT_DELIVERY_COMMENT = 'ORDERS_CHECKOUT_DELIVERY_COMMENT',
  CHECKOUT_CUSTOMER_INFO = 'ORDERS_CHECKOUT_CUSTOMER_INFO',
  CHECKOUT_PAYMENT = 'ORDERS_CHECKOUT_PAYMENT',

  FAVOURITE_BOOKS = 'FAVOURITES_FAVOURITE_BOOKS',
  FAVOURITES_COUNT = 'FAVOURITES_FAVOURITES_COUNT',
  IS_FAVOURITE = 'FAVOURITES_IS_FAVOURITE',

  MESSAGES = 'MESSAGES_MESSAGES',
  MESSAGES_COUNT = 'MESSAGES_MESSAGES_COUNT',

  CART_BOOKS = 'CART_BOOKS',
  CART_BOOK = 'CART_BOOK',
  CART_BOOK_ITEM_COUNT = 'CART_BOOK_ITEM_COUNT',
  CART_BOOKS_COUNT = 'CART_BOOKS_COUNT',
  CART_TOTAL_SUM = 'CART_TOTAL_SUM',
  CART_DISCOUNT = 'CART_DISCOUNT',
  CART_TOTAL_DISCOUNTED_SUM = 'CART_TOTAL_DISCOUNTED_SUM',
  CART_TOTAL_WEIGHT = 'CART_TOTAL_WEIGHT',
  CART_APPLIED_COUPON = 'CART_APPLIED_COUPON',
  CART_COUPONS = 'CART_COUPONS',
  CART_PROMOCODE = 'CART_PROMOCODE',
}
