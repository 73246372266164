import type { ActionContext, ActionTree } from 'vuex';
import type { State } from './state';
import type { AugmentedCommit, RootState } from '@/store';
import { ActionTypes } from '@/store/constants/action-types';
import { MutationTypes } from '@/store/constants/mutation-types';
import {
  getBookIds,
  setBookIds,
  addBookId,
  removeBookId,
} from '@/services/favourites.service';
import {
  getFavouriteBooks,
  addToFavourites,
  removeFromFavourites,
  type FavouritesResponse,
} from '@/api/favourites.api';
import { GetterTypes } from '@/store/constants/getter-types';
import type { BooksResponse } from '@/api/types';
import { broadcaster, BroadcasterMessage } from '@/broadcaster';

type AugmentedActionContext = {
  commit: AugmentedCommit;
} & Omit<ActionContext<State, RootState>, 'commit'>;

export interface Actions {
  [ActionTypes.LOAD_FAVOURITES](
    context: AugmentedActionContext,
  ): Promise<BooksResponse>;
  [ActionTypes.ADD_TO_FAVOURITES](
    context: AugmentedActionContext,
    bookId: number,
  ): Promise<FavouritesResponse>;
  [ActionTypes.REMOVE_FROM_FAVOURITES](
    context: AugmentedActionContext,
    bookId: number,
  ): Promise<FavouritesResponse>;
}

function broadcastFavourites() {
  broadcaster.post(BroadcasterMessage.FAVOURITES, getBookIds());
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [ActionTypes.LOAD_FAVOURITES]({
    state,
    commit,
  }): Promise<BooksResponse> {
    const [error, books] = await getFavouriteBooks(state.bookIds);

    if (books) {
      commit(MutationTypes.SET_FAVOURITES, books);
      setBookIds(books.map((book) => book.id));
    }

    return [error, books] as BooksResponse;
  },
  async [ActionTypes.ADD_TO_FAVOURITES](
    { rootGetters, commit },
    bookId,
  ): Promise<FavouritesResponse> {
    commit(MutationTypes.SET_FAVOURITE_IDS, addBookId(bookId));

    if (rootGetters[GetterTypes.LOGGED_IN]) {
      const [error] = await addToFavourites(bookId);
      if (error) {
        commit(MutationTypes.SET_FAVOURITE_IDS, removeBookId(bookId));

        return [error];
      }
    } else {
      broadcastFavourites();
    }

    return [null, getBookIds()];
  },
  async [ActionTypes.REMOVE_FROM_FAVOURITES](
    { rootGetters, commit },
    bookId,
  ): Promise<FavouritesResponse> {
    commit(MutationTypes.SET_FAVOURITE_IDS, removeBookId(bookId));

    if (!rootGetters[GetterTypes.LOGGED_IN]) {
      broadcastFavourites();

      return [null, getBookIds()];
    }

    const [error, bookIds] = await removeFromFavourites(bookId);

    if (error) {
      commit(MutationTypes.SET_FAVOURITE_IDS, addBookId(bookId));
    } else if (bookIds) {
      setBookIds(bookIds);
      commit(MutationTypes.SET_FAVOURITE_IDS, bookIds);
    }

    return [error, bookIds] as FavouritesResponse;
  },
};
