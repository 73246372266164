import type { User, UserInfoData } from '@/store/modules/user/types';
import {
  getStorageItem,
  removeStorageItem,
  setStorageItem,
} from '@/services/storage';

export const setTokenExpiresAt = (expiresAt: string) => {
  const user = getUser();
  user.tokenExpiresAt = expiresAt;
  setUser(user);
};

export const getUser = (): User => getStorageItem('user');

export const setUser = (user: User) => setStorageItem('user', user);

export const setUserInfo = (userInfo: UserInfoData) => {
  setUser({
    ...getUser(),
    ...userInfo,
  });
};

export const removeUser = () => removeStorageItem('user');
