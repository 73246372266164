import type { GetterTree } from 'vuex';
import type { State } from './state';
import type { RootState } from '@/store';
import { GetterTypes } from '@/store/constants/getter-types';
import type { UserInfoData } from '@/store/modules/user/types';

export interface Getters {
  [GetterTypes.LOGGED_IN](state: State): boolean;
  [GetterTypes.USER_ID](state: State): number | null;
  [GetterTypes.USER_NAME](state: State): string | null;
  [GetterTypes.USER_INFO](state: State): UserInfoData | null;
  [GetterTypes.TOKEN_EXPIRES_AT](state: State): Date | null;
}

export const getters: GetterTree<State, RootState> & Getters = {
  [GetterTypes.LOGGED_IN](state): boolean {
    return !!state.user;
  },
  [GetterTypes.USER_ID](state): number | null {
    return state.user?.id || null;
  },
  [GetterTypes.USER_NAME](state): string | null {
    return state.user?.name || null;
  },
  [GetterTypes.USER_INFO](state): UserInfoData | null {
    if (state.user) {
      return {
        name: state.user.name,
        email: state.user.email,
        phone: state.user.phone,
      };
    }

    return null;
  },
  [GetterTypes.TOKEN_EXPIRES_AT](state): Date | null {
    if (!state.user) {
      return null;
    }

    return new Date(state.user.tokenExpiresAt);
  },
};
