export type FormatCurrency = (
  value: number,
  currency?: string | null,
) => string;

const numberFormats = {} as Record<string, Intl.NumberFormat>;

function initNumberFormat(currency: string) {
  numberFormats[currency] = new Intl.NumberFormat('ru-RU', {
    style: 'currency',
    currency,
  });
}

initNumberFormat('RUB');

export function useCurrency() {
  const formatCurrency = (value: number, currency?: string | null): string => {
    currency = currency || 'RUB';

    if (!numberFormats[currency]) {
      initNumberFormat(currency);
    }

    return numberFormats[currency].format(value);
  };

  return formatCurrency as FormatCurrency;
}
