import type { DeliveryAddress } from '@/store/modules/orders/types';

const LOCALITY_TYPE_MAP = {
  CITY: 'г.',
  SETTLEMENT: 'п.',
};

export function addressToString(address?: DeliveryAddress | null): string {
  if (!address) {
    return '';
  }

  const { area, locality, localityType, street, housing, house, apartment } =
    address;

  const housingStr = housing ? `к. ${housing}, ` : '';
  const apartmentStr = apartment ? `, кв. ${apartment}` : '';

  return (
    `${area}, ` +
    LOCALITY_TYPE_MAP[localityType] +
    ` ${locality}, ул. ${street}, ${housingStr}д. ${house}${apartmentStr}`
  );
}
