import type { Message } from './types';
import { getCount } from '@/services/messages.service';

export type State = {
  messages: Message[];
  count: number;
};

export const state = {
  messages: [],
  count: getCount(),
} as State;
