export enum ActionTypes {
  LOAD_COMMON_STATE = 'LOAD_COMMON_STATE',
  DISABLE_TRANSITIONS = 'DISABLE_TRANSITIONS',
  ENABLE_TRANSITIONS = 'ENABLE_TRANSITIONS',

  // auth page actions
  LOGIN = 'USER_LOGIN',
  LOGOUT = 'USER_LOGOUT',
  REGISTER = 'USER_REGISTER',
  SEND_PASSWORD_RESET = 'USER_SEND_PASSWORD_RESET',
  RESET_PASSWORD = 'USER_RESET_PASSWORD',

  // profile page actions
  CHANGE_PASSWORD = 'USER_CHANGE_PASSWORD',
  CHANGE_USER_INFO = 'USER_CHANGE_USER_INFO',
  GET_USER_INFO = 'USER_GET_USER_INFO',

  CREATE_ORDER = 'ORDERS_CREATE_ORDER',
  SET_CHECKOUT_ACTIVE_STEP = 'ORDERS_SET_CHECKOUT_ACTIVE_STEP',
  RESET_CHECKOUT_STATE = 'ORDERS_RESET_CHECKOUT_STATE',
  SET_CHECKOUT_RETURN_STEP = 'ORDERS_SET_CHECKOUT_RETURN_STEP',
  TO_PREV_CHECKOUT_STEP = 'ORDERS_TO_PREV_CHECKOUT_STEP',
  TO_NEXT_CHECKOUT_STEP = 'ORDERS_TO_NEXT_CHECKOUT_STEP',
  TO_CHECKOUT_STEP_EDIT = 'ORDERS_TO_CHECKOUT_STEP_EDIT',
  SET_STEP_INFO = 'ORDERS_SET_STEP_INFO',
  SET_DELIVERY_TYPE = 'ORDERS_SET_DELIVERY_TYPE',
  SET_DELIVERY_INFO = 'ORDERS_SET_DELIVERY_INFO',
  UPDATE_DELIVERY_DATE = 'ORDERS_UPDATE_DELIVERY_DATE',
  SET_PICKPOINT_INFO = 'ORDERS_SET_PICKPOINT_INFO',
  SET_COURIER_INFO = 'ORDERS_SET_COURIER_INFO',
  SET_MAIL_INFO = 'ORDERS_SET_MAIL_INFO',
  SET_CUSTOMER_INFO = 'ORDERS_SET_CUSTOMER_INFO',
  SET_PAYMENT = 'ORDERS_SET_PAYMENT',
  CLEAR_CHECKOUT_DATA = 'ORDERS_CLEAR_CHECKOUT_DATA',

  LOAD_FAVOURITES = 'FAVOURITES_LOAD_FAVOURITES',
  ADD_TO_FAVOURITES = 'FAVOURITES_ADD_TO_FAVOURITES',
  REMOVE_FROM_FAVOURITES = 'FAVOURITES_REMOVE_FROM_FAVOURITES',

  LOAD_MESSAGES = 'MESSAGES_LOAD_MESSAGES',

  LOAD_CART = 'CART_LOAD_CART',
  SET_CART = 'CART_SET_CART',
  SET_CART_INFO = 'CART_SET_CART_INFO',
  ADD_TO_CART_STORE = 'CART_ADD_TO_CART_STORE',
  ADD_TO_CART = 'CART_ADD_TO_CART',
  REMOVE_FROM_CART_STORE = 'CART_REMOVE_FROM_CART_STORE',
  REMOVE_FROM_CART = 'CART_REMOVE_FROM_CART',
  SET_CART_STORE_BOOK_COUNT = 'CART_SET_CART_STORE_BOOK_COUNT',
  SET_CART_BOOK_COUNT = 'CART_SET_CART_BOOK_COUNT',
  SET_CART_COUPONS = 'CART_SET_CART_COUPONS',
  SET_CART_PROMOCODE = 'CART_SET_CART_PROMOCODE',
  APPLY_COUPON = 'CART_APPLY_COUPON',
  APPLY_PROMOCODE = 'CART_APPLY_PROMOCODE',
  CANCEL_BONUS = 'CART_CANCEL_BONUS',
  CLEAR_CART = 'CART_CLEAR_CART',
}
