import type { RouteLocationNormalized } from 'vue-router';
import { store } from '@/store';

export function lazyLoadStoreModules(to: RouteLocationNormalized) {
  if (to.path.match(/^\/(checkout|cart)/) && !store.hasModule('orders')) {
    import('@/store/modules/orders').then(({ ordersModule }) => {
      store.registerModule('orders', ordersModule);
    });
  }
}
