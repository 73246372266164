import { ref, type App, type InjectionKey } from 'vue';

const WIDTH_LAPTOP = 768;
const WIDTH_DESKTOP = 1200;

export type ViewSize = {
  isMobileView(): boolean;
  isLaptopView(): boolean;
  isDesktopView(): boolean;
};

export const viewSizeKey: InjectionKey<ViewSize> = Symbol();

const windowWidth = ref<number>(window.innerWidth);
// let windowHeight = ref<number>(window.innerHeight);

export const isMobileView = () => windowWidth.value < WIDTH_LAPTOP;

export const isLaptopView = () =>
  windowWidth.value >= WIDTH_LAPTOP && windowWidth.value < WIDTH_DESKTOP;

export const isDesktopView = () => windowWidth.value >= WIDTH_DESKTOP;

export default {
  install(app: App, options: { delay?: number }) {
    let _timer: ReturnType<typeof setTimeout> | undefined;
    const { delay: _delay = 50 } = options || {};

    const resizeHandler = () => {
      clearTimeout(_timer);

      _timer = setTimeout(() => {
        windowWidth.value = window.innerWidth;
        // windowHeight.value = window.innerHeight;
      }, _delay);
    };

    window.addEventListener('resize', resizeHandler);
    window.addEventListener('orientationchange', resizeHandler);

    app.config.globalProperties.$isMobileView = isMobileView;
    app.config.globalProperties.$isLaptopView = isLaptopView;
    app.config.globalProperties.$isDesktopView = isDesktopView;

    app.provide(viewSizeKey, {
      isMobileView,
      isLaptopView,
      isDesktopView,
    });
  },
};
