type CustomEventListener<T> = (event: CustomEvent<T>) => void;

class EventBus<DetailType = unknown> {
  private eventTarget: EventTarget;

  private eventListeners = {} as Record<
    string,
    CustomEventListener<DetailType>[]
  >;

  constructor(description = '') {
    this.eventTarget = document.appendChild(new Comment(description));
  }

  on(type: string, listener: CustomEventListener<DetailType>) {
    this.eventTarget.addEventListener(type, listener as EventListener);

    if (this.eventListeners[type] === undefined) {
      this.eventListeners[type] = [];
    }
    this.eventListeners[type].push(listener);
  }

  once(type: string, listener: CustomEventListener<DetailType>) {
    this.eventTarget.addEventListener(type, listener as EventListener, {
      once: true,
    });
  }

  off(type: string, listener?: CustomEventListener<DetailType>) {
    if (listener) {
      this.eventTarget.removeEventListener(
        type,
        (listener as EventListener) || null,
      );

      if (this.eventListeners[type]) {
        this.eventListeners[type] = this.eventListeners[type].filter(
          (storedListener) => {
            return storedListener !== listener;
          },
        );
      }
    } else if (this.eventListeners[type]) {
      this.eventListeners[type].forEach((storedListener) => {
        this.eventTarget.removeEventListener(
          type,
          storedListener as EventListener,
        );
      });

      delete this.eventListeners[type];
    }
  }

  emit(type: string, detail?: DetailType) {
    return this.eventTarget.dispatchEvent(new CustomEvent(type, { detail }));
  }
}

export default new EventBus<string>('my-event-bus');
