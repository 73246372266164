import type { SuccessStatus, SuccessStatusResponse } from './types';
import { get, patch, type HttpClientResponse } from '@/api/http';
import type {
  ChangePasswordData,
  UserInfoData,
} from '@/store/modules/user/types';

export type UserInfoResponse = HttpClientResponse<UserInfoData>;

export const changePassword = async (
  formData: ChangePasswordData,
): Promise<SuccessStatusResponse> =>
  patch<SuccessStatus>('profile/password', formData);

export const changeUserInfo = async (
  userInfo: UserInfoData,
): Promise<SuccessStatusResponse> =>
  patch<SuccessStatus>('profile/user', userInfo);

export const getUserInfo = async (): Promise<UserInfoResponse> =>
  get<UserInfoData>('profile/user');
