import type { RootState } from '@/store';
import { EffectEnum, type Coupon } from './types';

export function getCartTotalSum(state: RootState): number {
  return Math.trunc(
    Object.values(state.cart.cartInfo)
      .map((entry) => (entry.basePrice || entry.price) * entry.count)
      .reduce((a, b) => a + b, 0),
  );
}

export function getAppliedCoupon(state: RootState): Coupon | null {
  if (state.cart.couponId) {
    return (
      state.cart.coupons.find((coupon) => coupon.id == state.cart.couponId) ||
      null
    );
  }

  return null;
}

export function getCartDiscount(state: RootState): number {
  const priceDiscount = Object.values(state.cart.cartInfo)
    .filter((entry) => !!entry.basePrice)
    .map((entry) => ((entry.basePrice as number) - entry.price) * entry.count)
    .reduce((a, b) => a + b, 0);

  const bonus = getAppliedCoupon(state) || state.cart.promocode;

  let bonusDiscount = 0;
  if (bonus && bonus.effect === EffectEnum.PRICE) {
    bonusDiscount = (getCartTotalSum(state) / 100) * (bonus.value as number);
  }

  return Math.trunc(priceDiscount + bonusDiscount);
}

export function getCartBooksCount(state: RootState): number {
  return state.cart.cartInfo
    ? Object.values(state.cart.cartInfo).reduce(
        (sum, val) => sum + val.count,
        0,
      )
    : 0;
}
