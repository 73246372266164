import { getUser } from '@/services/user.service';
import type { RouteLocationNormalized } from 'vue-router';

export function authGuard(to: RouteLocationNormalized) {
  if (to.meta.requiresAuth && !getUser()) {
    return {
      path: '/auth/login',
      query: { redirect: to.fullPath },
    };
  }

  return true;
}
