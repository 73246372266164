import { waitFor } from '@/common/functions';
import { setTokenExpiresAt } from '@/services/user.service';
import { refreshToken } from '@/api/auth.api';

const REFRESH_TIMEOUT = 10000;

const RefreshState = {
  REFRESHING: 1,
  REFRESHED: 2,
  FAIL: 3,
} as const;

type RefreshStateType = (typeof RefreshState)[keyof typeof RefreshState];

class TokenRefresher {
  private refreshState: RefreshStateType | null = null;

  private async awaitTokenRefresh(): Promise<unknown> {
    return await waitFor(
      () => this.refreshState !== RefreshState.REFRESHING,
      REFRESH_TIMEOUT,
    );
  }

  private async _refreshToken(): Promise<void> {
    this.refreshState = RefreshState.REFRESHING;

    const [err, res] = await refreshToken();

    if (err || !res) {
      this.refreshState = RefreshState.FAIL;
      return Promise.reject(err);
    }

    setTokenExpiresAt(res.expiresAt);

    this.refreshState = RefreshState.REFRESHED;
  }

  async refreshToken(): Promise<void> {
    if (this.refreshState === RefreshState.REFRESHING) {
      await this.awaitTokenRefresh();
    } else {
      await this._refreshToken();
    }

    if (this.refreshState !== RefreshState.REFRESHED) {
      return Promise.reject();
    }
  }
}

export const useTokenRefresher = () => new TokenRefresher();
