import type { App, InjectionKey } from 'vue';
import VueToast from 'vue-toast-notification';

export type ToastError =
  | {
      noToast?: boolean;
      message?: string;
      response?: {
        data?: {
          message?: string;
          error?: string;
        };
      };
    }
  | string;

export type Toast = {
  successToast(message: string): void;
  errorToast(errorToast: ToastError): void;
  infoToast(infoToast: string): void;
};

export const toastKey: InjectionKey<Toast> = Symbol();

export default {
  install(app: App) {
    app.use(VueToast);

    async function openToast(
      message: string,
      type: 'success' | 'error' | 'info',
    ) {
      app.config.globalProperties.$toast.open({
        message: message,
        type: type,
        duration: 5000,
        dismissible: true,
        pauseOnHover: true,
      });
    }

    function successToast(message: string) {
      openToast(message, 'success');
    }

    function errorToast(error: ToastError) {
      if (typeof error === 'object') {
        if (error.noToast) {
          return;
        }

        const errorMessage =
          error.response?.data?.message ||
          error.response?.data?.error ||
          (Object.hasOwn(error, 'toString') ? error.toString() : null) ||
          error.message;

        if (errorMessage) {
          openToast(errorMessage, 'error');
        }
      } else if (error) {
        openToast(error, 'error');
      }
    }

    function infoToast(message: string) {
      openToast(message, 'info');
    }

    app.config.globalProperties.$successToast = successToast;
    app.config.globalProperties.$errorToast = errorToast;
    app.config.globalProperties.$infoToast = infoToast;

    app.provide(toastKey, {
      successToast,
      errorToast,
      infoToast,
    });
  },
};
