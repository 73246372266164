import { getStorageItem, setStorageItem } from '@/services/storage';

export const getBookIds = (): number[] =>
  (getStorageItem('favourites') ?? []).map((id: string | number) => +id);

export const setBookIds = (bookIds: number[]): void =>
  setStorageItem('favourites', bookIds);

export const addBookId = (bookId: number): number[] => {
  const bookIds = getBookIds();

  if (!bookIds.includes(bookId)) {
    bookIds.push(bookId);
    setBookIds(bookIds);
  }

  return bookIds;
};

export const removeBookId = (bookId: number): number[] => {
  const bookIds = getBookIds().filter((id) => id != bookId);

  setBookIds(bookIds);

  return bookIds;
};
