import axios from 'axios';
import type { AxiosInstance } from 'axios';
import type { RequestConfig, RequestData, HttpClientResponse } from '../types';
import { HttpClientBase } from './base';

export const createAxiosInstance = (baseUrl: string) =>
  axios.create({
    baseURL: baseUrl,
    headers: {
      'Content-Type': 'application/json',
    },
  });

export class AxiosHttpClient extends HttpClientBase {
  constructor(private axiosInstance: AxiosInstance) {
    super();
  }

  protected async executeRequest<D = RequestData, R = unknown>(
    config: RequestConfig<D>,
  ): Promise<HttpClientResponse<R>> {
    const { data } = await this.axiosInstance.request<R>(config);

    return [null, data];
  }
}
