import type { Book } from '@/pages/book/types';
import type { BooksResponse } from '@/api/types';
import { get, post, delete_, type HttpClientResponse } from '@/api/http';

export type FavouritesResponse = HttpClientResponse<number[]>;

export const getFavouriteBooks = async (
  bookIds?: number[] | null,
): Promise<BooksResponse> => {
  const params = {} as { favourite_book_ids?: string };

  if (bookIds?.length) {
    params.favourite_book_ids = bookIds.join(',');
  }

  return get<Book[]>(`favourites`, params);
};

export const addToFavourites = async (
  bookId: number,
): Promise<FavouritesResponse> => {
  const res = await post<number[]>(`favourites/` + bookId);
  res[1] = res[1]?.map((bookId) => +bookId);

  return res;
};

export const removeFromFavourites = async (
  bookId: number,
): Promise<FavouritesResponse> => {
  const res = await delete_<number[]>(`favourites/` + bookId);
  res[1] = res[1]?.map((bookId) => +bookId);

  return res;
};
