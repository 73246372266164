import { UNAUTHORIZED } from '@/constants/StatusCodes';
import type {
  HttpClientError,
  RequestConfigWithRetry,
  ResponseErrorHook,
} from '@/api/http/types';
import { HttpClientBase } from '@/api/http/client/base';
import type { TokenService } from '@/services/token/tokenService';

export const useRefreshTokenAndRetry = (
  tokenService: TokenService,
): ResponseErrorHook => {
  const isTokenRejected = (err: HttpClientError) =>
    err.response?.status === UNAUTHORIZED;

  const needToRefreshToken = (err: HttpClientError) => {
    const url = err.config!.url as string;

    return (
      tokenService.isTokenRefreshAllowed(url) &&
      isTokenRejected(err) &&
      !err.config!._retry
    );
  };

  return async function <T = unknown>(
    this: HttpClientBase,
    err: HttpClientError,
  ) {
    if (!needToRefreshToken(err)) {
      return [err];
    }

    const config = err.config as RequestConfigWithRetry<T>;

    config._retry = true;

    try {
      await tokenService.refreshToken(config);

      return await this.executeRequest(config);
    } catch (e) {
      err.noToast = (e as { noToast?: boolean })?.noToast || false;

      return [err];
    } finally {
      config._retry = false;
    }
  };
};
