import type { MutationTree } from 'vuex';
import type { State } from './state';
import type { User, UserInfoData } from './types';
import { MutationTypes } from '@/store/constants/mutation-types';

export interface Mutations<S = State> {
  [MutationTypes.LOGIN](state: S, user: User): void;
  [MutationTypes.LOGOUT](state: S): void;
  [MutationTypes.SET_TOKEN_EXPIRES_AT](state: S, tokenExpiresAt: string): void;
  [MutationTypes.SET_USER_INFO](state: S, userInfo: UserInfoData): void;
}

export const mutations: MutationTree<State> & Mutations = {
  [MutationTypes.LOGIN](state, user): void {
    state.user = user;
  },
  [MutationTypes.LOGOUT](state): void {
    state.user = null;
  },
  [MutationTypes.SET_TOKEN_EXPIRES_AT](state, tokenExpiresAt): void {
    state.user = { ...state.user!, tokenExpiresAt };
  },
  [MutationTypes.SET_USER_INFO](state, userInfo): void {
    state.user = {
      ...state.user!,
      ...userInfo,
    };
  },
};
