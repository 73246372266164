export enum MutationTypes {
  SET_NO_TRANSITIONS = 'SET_NO_TRANSITIONS',

  LOGIN = 'USER_LOGIN',
  LOGOUT = 'USER_LOGOUT',
  SET_TOKEN_EXPIRES_AT = 'USER_SET_TOKEN_EXPIRES_AT',
  SET_USER_INFO = 'USER_SET_USER_INFO',

  SET_CHECKOUT_DATA = 'ORDERS_SET_CHECKOUT_DATA',
  SET_CHECKOUT_ACTIVE_STEP = 'ORDERS_SET_CHECKOUT_ACTIVE_STEP',
  SET_CHECKOUT_RETURN_STEP = 'ORDERS_SET_CHECKOUT_RETURN_STEP',
  SET_STEP_INFO = 'ORDERS_SET_STEP_INFO',
  SET_DELIVERY_TYPE = 'ORDERS_SET_DELIVERY_TYPE',
  SET_DELIVERY_DATE = 'ORDERS_SET_DELIVERY_DATE',
  SET_DELIVERY_PRICE = 'ORDERS_SET_DELIVERY_PRICE',
  REMOVE_DELIVERY_INFO = 'ORDERS_REMOVE_DELIVERY_INFO',
  SET_PICKPOINT = 'ORDERS_SET_PICKPOINT',
  SET_DELIVERY_ADDRESS = 'ORDERS_SET_DELIVERY_ADDRESS',
  SET_DELIVERY_COMMENT = 'ORDERS_SET_DELIVERY_COMMENT',
  SET_CUSTOMER_INFO = 'ORDERS_SET_CUSTOMER_INFO',
  SET_PAYMENT = 'ORDERS_SET_PAYMENT',

  SET_FAVOURITES = 'FAVOURITES_SET_FAVOURITES',
  SET_FAVOURITE_IDS = 'FAVOURITES_SET_FAVOURITE_IDS',

  SET_MESSAGES = 'MESSAGES_SET_MESSAGES',
  SET_MESSAGES_COUNT = 'MESSAGES_SET_MESSAGES_COUNT',

  SET_CART_INFO = 'CART_SET_CART_INFO',
  SET_CART_BOOKS = 'CART_SET_CART_BOOKS',
  SET_CART_BOOK = 'CART_SET_CART_BOOK',
  SET_CART_BOOK_COUNT = 'CART_SET_CART_BOOK_COUNT',
  SET_CART_LOADED = 'CART_SET_CART_LOADED',
  SET_CART_LOAD_LOCK = 'SET_CART_LOAD_LOCK',
  REMOVE_FROM_CART = 'CART_REMOVE_FROM_CART',
  SET_CART_COUPONS = 'CART_SET_CART_COUPONS',
  SET_CART_COUPON_ID = 'CART_SET_CART_COUPON_ID',
  SET_CART_PROMOCODE = 'CART_SET_CART_PROMOCODE',
  SET_CART_PROMOCODE_ID = 'CART_SET_CART_PROMOCODE_ID',
}
