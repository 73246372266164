import type { SuccessStatus, SuccessStatusResponse } from './types';
import { post, type HttpClientResponse } from '@/api/http';
import type {
  LoginUserData,
  SendPasswordResetData,
  RegisterUserData,
  ResetPasswordData,
} from '@/store/modules/user/types';

type ResetResponseData = {
  success: boolean;
  status: string;
  notFound: boolean;
};

type LoginResponseData = {
  user: {
    id: number;
    name: string;
    email: string;
    phone?: string;
  };
  tokenType: string;
  expiresIn: number;
  expiresAt: string;
};

export type LoginResponse = HttpClientResponse<LoginResponseData>;
export type ResetResponse = HttpClientResponse<
  ResetResponseData,
  ResetResponseData
>;

const getEmailConfirmUrl = () => {
  if (window.location.pathname.includes('email/verify')) {
    return window.location.href;
  }

  const redirectUrl = new URLSearchParams(window.location.search).get(
    'redirect',
  );
  if (!redirectUrl) {
    throw Error('Invalid email confirm url');
  }

  return redirectUrl;
};

export const login = async (userData: LoginUserData): Promise<LoginResponse> =>
  post<LoginResponseData>('auth/login', userData);

export const logout = async (): Promise<SuccessStatusResponse> =>
  post<SuccessStatus>('auth/logout');

export const register = async (
  userData: RegisterUserData,
  couponId?: number | null,
): Promise<SuccessStatusResponse> =>
  post<SuccessStatus>('auth/register', { userData, couponId });

export const refreshToken = async (): Promise<LoginResponse> =>
  post<LoginResponseData>('auth/refresh');

export const sendResetLink = async (
  formData: SendPasswordResetData,
): Promise<ResetResponse> =>
  post<ResetResponseData>('/forgot-password', formData, {
    baseURL: '/',
  }) as Promise<ResetResponse>;

export const resetPassword = async (
  formData: ResetPasswordData,
): Promise<ResetResponse> =>
  post<ResetResponseData>('/reset-password', formData, {
    baseURL: '/',
  }) as Promise<ResetResponse>;

export const verifyEmail = async (): Promise<SuccessStatusResponse> =>
  post<SuccessStatus>(getEmailConfirmUrl(), {}, { baseURL: '/' });

export const resendEmailNotification =
  async (): Promise<SuccessStatusResponse> =>
    post<SuccessStatus>(
      '/email/verification-notification',
      {},
      { baseURL: '/' },
    );
