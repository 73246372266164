import { httpClient } from './client';
import type {
  PartialRequestConfig,
  RequestData,
  RequestParams,
  HttpClientResponse,
} from './types';

export type {
  HttpClientResponse,
  HttpClientErrorResponse,
  HttpClientError,
} from './types';

export const get = async <T = unknown>(
  url: string,
  params?: object,
): Promise<HttpClientResponse<T>> =>
  httpClient.request<RequestData, T>({
    params: params as RequestParams | undefined,
    url,
    method: 'GET',
  });

export const post = async <T = unknown>(
  url: string,
  data?: RequestData,
  config?: PartialRequestConfig,
): Promise<HttpClientResponse<T>> =>
  httpClient.request<RequestData, T>({
    ...config,
    url,
    data,
    method: 'POST',
  });

export const put = async <T = unknown>(
  url: string,
  data?: RequestData,
): Promise<HttpClientResponse<T>> =>
  httpClient.request<RequestData, T>({
    url,
    data,
    method: 'PUT',
  });

export const patch = async <T = unknown>(
  url: string,
  data?: RequestData,
): Promise<HttpClientResponse<T>> =>
  httpClient.request<RequestData, T>({
    url,
    data,
    method: 'PATCH',
  });

export const delete_ = async <T = unknown>(
  url: string,
): Promise<HttpClientResponse<T>> =>
  httpClient.request<RequestData, T>({
    url,
    method: 'DELETE',
  });
