import type { BroadcasterInterface } from './Broadcaster';
import { rIC } from '@/common/idle';

const original = {
  value: null as BroadcasterInterface | null,
};

let resolveIsReady: (() => void) | null = null;
const isReadyProxy = new Promise<void>((resolve) => (resolveIsReady = resolve));

export const broadcaster = new Proxy(original, {
  get(target, prop: keyof BroadcasterInterface | 'isReady') {
    if (prop === 'isReady') {
      return () => isReadyProxy;
    }

    if (target.value === null) {
      return () => broadcaster;
    }

    if (typeof target.value[prop] !== 'function') {
      return target.value[prop];
    }

    return (...args: unknown[]) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const res = target.value![prop](...args);

      if (res !== target.value) {
        return res;
      }

      return broadcaster;
    };
  },
}) as unknown as BroadcasterInterface & { isReady: () => Promise<void> };

rIC(() => {
  import('./init')
    .then((module) => module.init())
    .then((broadcaster) => {
      original.value = broadcaster;
      resolveIsReady?.();
    });
});

export { BroadcasterMessage } from './types';
