import { getCart, getPromocodeId, getCouponId } from '@/services/cart.service';
import type { OrderBook } from '@/components/OrderBook/types';
import type { CartInfo, Coupon, Promocode } from './types';

export type State = {
  cartInfo: CartInfo;
  cartBooks: OrderBook[];
  loaded: boolean;
  loadLock: boolean;

  promocodeId: number | null;
  promocode: Promocode | null;

  couponId: number | null;
  coupons: Coupon[];
};

export const state = {
  cartInfo: getCart() || {},
  cartBooks: [],
  loaded: false,
  loadLock: false,

  promocodeId: getPromocodeId(),
  promocode: null,

  couponId: getCouponId(),
  coupons: [],
} as State;
