import { get, post, delete_, put, type HttpClientResponse } from '@/api/http';
import type { OrderBook } from '@/components/OrderBook/types';

export type CartResponse = HttpClientResponse<OrderBook[]>;

type CartBookCountResponse = HttpClientResponse<{
  count: number;
}>;

export const getCart = async (
  bookCounts: Record<number, number>,
): Promise<CartResponse> =>
  get<OrderBook[]>(`cart`, { bookCounts: JSON.stringify(bookCounts) });

export const addToCart = async (
  bookVariationId: number,
): Promise<HttpClientResponse> => post<never>(`cart/${bookVariationId}`);

export const removeFromCart = async (
  bookVariationId: number,
): Promise<HttpClientResponse> => delete_<never>(`cart/${bookVariationId}`);

export const setCartBookCount = async (
  bookVariationId: number,
  count: number,
): Promise<CartBookCountResponse> =>
  put<{ count: number }>(`cart/${bookVariationId}/${count}`);
