import type { GetterTree } from 'vuex';
import type { State } from './state';
import type { RootState } from '@/store';
import { GetterTypes } from '@/store/constants/getter-types';
import type { OrderBook } from '@/components/OrderBook/types';
import type { Coupon, Promocode } from './types';
import {
  getAppliedCoupon,
  getCartBooksCount,
  getCartDiscount,
  getCartTotalSum,
} from '@/store/modules/cart/getter-utils';

export interface Getters {
  [GetterTypes.CART_BOOKS](state: State): OrderBook[];
  [GetterTypes.CART_BOOK](
    state: State,
  ): (bookVariationId: number) => OrderBook | null;
  [GetterTypes.CART_BOOK_ITEM_COUNT](
    state: State,
  ): (bookVariationId: number) => number;
  [GetterTypes.CART_BOOKS_COUNT](
    state: State,
    getters: AugmentedGetters,
    rootState: RootState,
  ): number;
  [GetterTypes.CART_TOTAL_SUM](
    state: State,
    getters: AugmentedGetters,
    rootState: RootState,
  ): number;
  [GetterTypes.CART_DISCOUNT](
    state: State,
    getters: AugmentedGetters,
    rootState: RootState,
  ): number;
  [GetterTypes.CART_TOTAL_DISCOUNTED_SUM](
    state: State,
    getters: AugmentedGetters,
    rootState: RootState,
  ): number;
  [GetterTypes.CART_TOTAL_WEIGHT](state: State): string;
  [GetterTypes.CART_APPLIED_COUPON](
    state: State,
    getters: AugmentedGetters,
    rootState: RootState,
  ): Coupon | null;
  [GetterTypes.CART_COUPONS](state: State): Coupon[];
  [GetterTypes.CART_PROMOCODE](state: State): Promocode | null;
}

type AugmentedGetters = {
  [K in keyof Getters]: ReturnType<Getters[K]>;
};

export const getters: GetterTree<State, RootState> & Getters = {
  [GetterTypes.CART_BOOKS](state): OrderBook[] {
    return state.cartBooks;
  },
  [GetterTypes.CART_BOOK]: (state) => (bookVariationId) => {
    return (
      state.cartBooks.find(
        (book) => book.variations[0].id == bookVariationId,
      ) || null
    );
  },
  [GetterTypes.CART_BOOK_ITEM_COUNT]: (state) => (bookVariationId) => {
    return state.cartInfo[bookVariationId]?.count || 0;
  },
  [GetterTypes.CART_BOOKS_COUNT](state, getters, rootState): number {
    return getCartBooksCount(rootState);
  },
  [GetterTypes.CART_TOTAL_SUM](state, getters, rootState): number {
    return getCartTotalSum(rootState);
  },
  [GetterTypes.CART_DISCOUNT](state, getters, rootState): number {
    return getCartDiscount(rootState);
  },
  [GetterTypes.CART_TOTAL_DISCOUNTED_SUM](state, getters, rootState): number {
    return getCartTotalSum(rootState) - getCartDiscount(rootState);
  },
  [GetterTypes.CART_TOTAL_WEIGHT](state): string {
    return (
      state.cartBooks
        .map((book) => book.weight * book.count)
        .reduce((a, b) => a + b, 0) / 1000
    ).toFixed(2);
  },
  [GetterTypes.CART_COUPONS](state): Coupon[] {
    return state.coupons;
  },
  [GetterTypes.CART_APPLIED_COUPON](state, getters, rootState): Coupon | null {
    return getAppliedCoupon(rootState);
  },
  [GetterTypes.CART_PROMOCODE](state): Promocode | null {
    return state.promocode;
  },
};
