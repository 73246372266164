import { useStore } from '@/store';
import { inject, watch, ref } from 'vue';
import { type Toast, toastKey } from '@/plugins/vue-toast-notification';
import { ActionTypes } from '@/store/constants/action-types';
import { broadcaster, BroadcasterMessage } from '@/broadcaster';
import { rIC } from '@/common/idle';
import { isAuthPage } from '@/common/functions';

export function useApp() {
  const store = useStore();
  const { errorToast } = inject(toastKey) as Toast;

  const isOnline = ref(true);
  let checkOnline: (() => Promise<boolean>) | null = null;

  const loadCheckOnline = () =>
    import('@/composables/useCheckOnline').then((useCheckOnlineModule) => {
      const { checkOnline: _checkOnline } =
        useCheckOnlineModule.useCheckOnline(isOnline);

      checkOnline = _checkOnline;

      watch(
        () => isOnline.value,
        () => {
          if (isOnline.value) {
            bootUp();
          } else {
            shutdown();
          }
        },
      );
    });

  rIC(loadCheckOnline);

  const bootUp = async () => {
    if (!isAuthPage()) {
      const [error] = await store.dispatch(ActionTypes.LOAD_COMMON_STATE);

      if (error) {
        errorToast(error);
      }

      if (await checkOnline?.()) {
        await broadcaster.isReady();

        broadcaster
          .listen(BroadcasterMessage.FAVOURITES)
          .listen(BroadcasterMessage.ADDED_TO_CART)
          .listen(BroadcasterMessage.REMOVED_FROM_CART)
          .listen(BroadcasterMessage.COUNT_CHANGED_IN_CART)
          .listen(BroadcasterMessage.ORDER_CREATED)
          .listen(BroadcasterMessage.USER_INFO);
      }
    }
  };

  const shutdown = async () => {
    if (await checkOnline?.()) {
      broadcaster
        .stopListening(BroadcasterMessage.FAVOURITES)
        .stopListening(BroadcasterMessage.ADDED_TO_CART)
        .stopListening(BroadcasterMessage.REMOVED_FROM_CART)
        .stopListening(BroadcasterMessage.COUNT_CHANGED_IN_CART)
        .stopListening(BroadcasterMessage.ORDER_CREATED)
        .stopListening(BroadcasterMessage.USER_INFO);
    }

    broadcaster.disconnect();
  };

  return { isOnline, bootUp, shutdown };
}
