import type { MutationTree } from 'vuex';
import type { State } from './state';
import { MutationTypes } from '@/store/constants/mutation-types';
import type { Book } from '@/pages/book/types';

export interface Mutations<S = State> {
  [MutationTypes.SET_FAVOURITES](state: S, books: Book[]): void;
  [MutationTypes.SET_FAVOURITE_IDS](state: S, bookIds: number[]): void;
}

export const mutations: MutationTree<State> & Mutations = {
  [MutationTypes.SET_FAVOURITES](state, books): void {
    state.bookIds = books.map((book) => book.id);
    state.books = books;
  },
  [MutationTypes.SET_FAVOURITE_IDS](state, bookIds): void {
    state.bookIds = bookIds;
    state.books = state.books.filter((book) => bookIds.includes(book.id));
  },
};
