import type { CartInfo } from '@/store/modules/cart/types';

export function toBookCounts(cartInfo: CartInfo): Record<number, number> {
  return Object.fromEntries(
    Object.entries(cartInfo).map(([bookVariationId, item]) => [
      +bookVariationId,
      item.count,
    ]),
  );
}
