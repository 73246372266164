import type { MutationTree } from 'vuex';
import type { UserMutations } from '@/store/modules/user';
import type { OrdersMutations } from '@/store/modules/orders';
import type { FavouritesMutations } from '@/store/modules/favourites';
import type { MessagesMutations } from '@/store/modules/messages';
import type { CartMutations } from '@/store/modules/cart';
import type { State } from './state';
import { MutationTypes } from '@/store/constants/mutation-types';

export interface Mutations
  extends RootMutations,
    UserMutations,
    OrdersMutations,
    FavouritesMutations,
    MessagesMutations,
    CartMutations {}

interface RootMutations<S = State> {
  [MutationTypes.SET_NO_TRANSITIONS](state: S, noTransitions: boolean): void;
}

export const mutations: MutationTree<State> & RootMutations = {
  [MutationTypes.SET_NO_TRANSITIONS](state, noTransitions): void {
    state.noTransitions = noTransitions;
  },
};
