export function waitFor(
  conditionFunction: (...args: unknown[]) => unknown,
  timeoutMs: number,
) {
  const delay = 400;

  const poll = (resolve: (value?: unknown) => unknown) => {
    timeoutMs -= delay;
    if (timeoutMs <= 0) {
      resolve();
    }

    if (conditionFunction()) {
      resolve();
    } else setTimeout(() => poll(resolve), delay);
  };

  return new Promise(poll);
}
