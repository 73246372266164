const createQueueMicrotaskViaPromises = () => {
  return (microtask: () => void) => {
    Promise.resolve().then(microtask);
  };
};

const createQueueMicrotaskViaMutationObserver = () => {
  let i = 0;
  let microtaskQueue: (() => void)[] = [];

  const observer = new MutationObserver(() => {
    microtaskQueue.forEach((microtask) => microtask());
    microtaskQueue = [];
  });

  const node = document.createTextNode('');

  observer.observe(node, { characterData: true });

  return (microtask: () => void) => {
    microtaskQueue.push(microtask);

    // Trigger a mutation observer callback, which is a microtask.
    node.data = String(++i % 2);
  };
};

/**
 * Queues a function to be run in the next microtask. If the browser supports
 * Promises, those are used. Otherwise it falls back to MutationObserver.
 * Note: since Promise polyfills are popular but not all support microtasks,
 * we check for native implementation rather than a polyfill.
 */
export const queueMicrotask =
  typeof Promise === 'function' &&
  Promise.toString().indexOf('[native code]') > -1
    ? createQueueMicrotaskViaPromises()
    : createQueueMicrotaskViaMutationObserver();
